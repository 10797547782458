import posthog from "posthog-js"

posthog.init("phc_kBoE26XYUtRcT0mQdPAbHDu4rY22D62MQ8YNQwWM1Ly", {
  api_host: process.env.REACT_APP_POSTHOG_API_HOST,
  ui_host: "https://eu.posthog.com",
  feature_flag_request_timeout_ms: 1000,
  bootstrap: {
    featureFlags: {},
  },
})
