import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"
import { RootState } from "./store"
import { Message } from "./chatsSlice"

export type ImageGallery = {
  visible: boolean
  initialImage?: string
}

export type PDFViewer = {
  visible: boolean
  PDFMessage?: Message
}

export type Search = {
  globalSearchVisible: boolean
  chatSearchVisible: boolean
}

export type Overlays = {
  imageGallery: ImageGallery
  PDFViewer: PDFViewer
  search: Search
}

const initialState: Overlays = {
  imageGallery: {
    visible: false,
  },
  PDFViewer: {
    visible: false,
  },
  search: {
    globalSearchVisible: false,
    chatSearchVisible: false,
  },
}

export const overlaysSlice = createSlice({
  name: "overlays",
  initialState,
  reducers: {
    updateImageGallery: (
      state,
      action: PayloadAction<Partial<ImageGallery>>,
    ) => {
      return {
        ...state,
        imageGallery: {
          ...state.imageGallery,
          ...action.payload,
        },
      }
    },
    showImageGallery: (state) => {
      return {
        ...state,
        imageGallery: {
          ...state.imageGallery,
          visible: true,
        },
      }
    },
    hideImageGallery: (state) => {
      return {
        ...state,
        imageGallery: {
          visible: false,
        },
      }
    },
    updatePDFViewer: (state, action: PayloadAction<Partial<PDFViewer>>) => {
      return {
        ...state,
        PDFViewer: {
          ...state.PDFViewer,
          ...action.payload,
        },
      }
    },
    showPDFViewer: (state) => {
      return {
        ...state,
        PDFViewer: {
          ...state.PDFViewer,
          visible: true,
        },
      }
    },
    hidePDFViewer: (state) => {
      return {
        ...state,
        PDFViewer: {
          visible: false,
        },
      }
    },
    updateSearch: (state, action: PayloadAction<Partial<Search>>) => {
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload,
        },
      }
    },
    showGlobalSearch: (state) => {
      return {
        ...state,
        search: {
          ...state.search,
          globalSearchVisible: true,
        },
      }
    },
    hideGlobalSearch: (state) => {
      return {
        ...state,
        search: {
          ...state.search,
          globalSearchVisible: false,
        },
      }
    },
    showChatSearch: (state) => {
      return {
        ...state,
        search: {
          ...state.search,
          chatSearchVisible: true,
        },
      }
    },
    hideChatSearch: (state) => {
      return {
        ...state,
        search: {
          ...state.search,
          chatSearchVisible: false,
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState)
  },
})

export const selectOverlays = (state: RootState) => {
  return state.overlays
}

export const selectImageGallery = createSelector(
  [selectOverlays],
  (overlays) => overlays.imageGallery,
)

export const isImageGalleryVisible = createSelector(
  [selectImageGallery],
  (imageGallery) => imageGallery.visible,
)

export const selectPDFViewer = createSelector(
  [selectOverlays],
  (overlays) => overlays.PDFViewer,
)

export const isPDFViewerVisible = createSelector(
  [selectPDFViewer],
  (PDFViewer) => PDFViewer.visible,
)

export const selectSearch = createSelector(
  [selectOverlays],
  (overlays) => overlays.search,
)

export const isGlobalSearchVisible = createSelector(
  [selectSearch],
  (search) => search.globalSearchVisible,
)

export const isChatSearchVisible = createSelector(
  [selectSearch],
  (search) => search.chatSearchVisible,
)

export const {
  updateImageGallery,
  showImageGallery,
  hideImageGallery,
  updatePDFViewer,
  showPDFViewer,
  hidePDFViewer,
  updateSearch,
  showGlobalSearch,
  hideGlobalSearch,
  showChatSearch,
  hideChatSearch,
} = overlaysSlice.actions
export default overlaysSlice.reducer
