import React, {
  ForwardedRef,
  RefObject,
  forwardRef,
  memo,
  useCallback,
  useContext,
  useState,
} from "react"
import {
  MessageDisplayType,
  MessageGroup,
  type Message,
} from "../../reducers/chatsSlice"
import { MessageStatus } from "../../lib/messageHelpers"
import { ChatInputTextAreaRef } from "../ChatInput"
import MessageBubleBody from "./MessageBubleBody"
import MessageImageGroupBubleBody from "./MessageImageGroupBubleBody"
import MessageRow from "./MessageRow"
import XmppMessageRetractionApi from "../../api/xmppMessageRetractionApi"
import { XmppContext } from "../../stream/xmppClient"
import { Button, Dialog, DialogBody, DialogFooter, H4 } from "@blueprintjs/core"
import DayMarker from "../DayMarker"
import {
  areMessageFromDifferentDay,
  getCurrentMessageDate,
} from "./utils/messageDate"

type MessageRowOrImageGroupProps = {
  messageOrMessageGroup: Message | MessageGroup
  previousMessageOrMessageGroup: Message | MessageGroup | undefined
  className?: string
  status?: MessageStatus | undefined
  highlight?: boolean
  grouped?: boolean
  withDriver?: boolean
  chatInputRef: RefObject<ChatInputTextAreaRef>
  scrollToMessage?: (id: string) => void
  expand: (messageOrMessageGroup: Message | MessageGroup) => void
  chatJid: string
}

const MessageRowOrImageGroupRow = forwardRef<
  HTMLDivElement,
  MessageRowOrImageGroupProps
>(
  (
    {
      messageOrMessageGroup,
      previousMessageOrMessageGroup,
      status,
      highlight = false,
      grouped = false,
      withDriver = false,
      className,
      chatInputRef,
      scrollToMessage,
      expand,
      chatJid,
      ...props
    }: MessageRowOrImageGroupProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { client } = useContext(XmppContext)
    const [deleteMessageCandidate, setDeleteMessageCandidate] = useState<
      Message | undefined
    >()

    const onMessageDelete = useCallback(async (message: Message) => {
      setDeleteMessageCandidate(message)
    }, [])

    const confirmMessageDelete = async () => {
      if (!deleteMessageCandidate) return

      await XmppMessageRetractionApi.retract(
        client,
        deleteMessageCandidate,
        chatJid,
      )
      setDeleteMessageCandidate(undefined)
    }

    const cancellMessageDelete = () => {
      setDeleteMessageCandidate(undefined)
    }

    const onExpand = useCallback(() => {
      expand(messageOrMessageGroup)
    }, [messageOrMessageGroup, expand])

    // If there is no previous message, it's the first message,
    // so we want to have a marker. Return true.
    const isDifferentDay =
      !previousMessageOrMessageGroup ||
      areMessageFromDifferentDay(
        messageOrMessageGroup,
        previousMessageOrMessageGroup,
      )

    return (
      <div ref={ref} {...props}>
        <Dialog
          title={<H4>Delete message</H4>}
          isOpen={deleteMessageCandidate !== undefined}
          onClose={cancellMessageDelete}
          className="bg-white"
        >
          <DialogBody>
            <div className="mb-4">
              Are you sure you want to delete this message permanently?
            </div>
            <div className="bg-message-bubble-color p-3 rounded-lg">
              {deleteMessageCandidate && (
                <MessageBubleBody
                  message={deleteMessageCandidate}
                  onDelete={() => {}}
                  chatInputRef={{ current: null }}
                  naked
                />
              )}
            </div>
          </DialogBody>
          <DialogFooter
            minimal
            actions={
              <>
                <Button
                  large
                  minimal
                  onClick={cancellMessageDelete}
                  text="Cancel"
                />
                <Button
                  large
                  intent="danger"
                  onClick={confirmMessageDelete}
                  text="Yes, delete this message"
                />
              </>
            }
          ></DialogFooter>
        </Dialog>
        {isDifferentDay && (
          <DayMarker date={getCurrentMessageDate(messageOrMessageGroup)} />
        )}
        {messageOrMessageGroup.displayType ===
        MessageDisplayType.MessageGroup ? (
          <MessageRow
            messageForRowActions={messageOrMessageGroup.messages[0]}
            chatInputRef={chatInputRef}
            className={className}
            status={status}
            grouped={grouped}
            scrollToMessage={scrollToMessage}
            isImageGroup
            onDelete={onMessageDelete}
          >
            <MessageImageGroupBubleBody
              messages={messageOrMessageGroup.messages}
              onExpand={onExpand}
              status={status}
              highlight={highlight}
              withDriver={withDriver}
            />
          </MessageRow>
        ) : (
          <MessageRow
            chatInputRef={chatInputRef}
            className={className}
            grouped={grouped}
            scrollToMessage={scrollToMessage}
            status={status}
            messageForRowActions={messageOrMessageGroup}
            key={messageOrMessageGroup.id}
            onDelete={onMessageDelete}
          >
            <MessageBubleBody
              message={messageOrMessageGroup}
              status={status}
              withDriver={withDriver}
              chatInputRef={chatInputRef}
              grouped={grouped}
              highlight={highlight}
              onDelete={onMessageDelete}
              naked={status && ["not_sent", "scheduled"].includes(status)}
            />
          </MessageRow>
        )}
      </div>
    )
  },
)

export default memo(MessageRowOrImageGroupRow)
