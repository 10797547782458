import React, { useCallback, useContext } from "react"
import { XmppContext } from "../stream/xmppClient"
import classNames from "classnames"
import css from "./ChatListHeader.module.css"
import cssUtils from "./utilsCss.module.css"
import JoinChatPopover from "./JoinChatPopover"
import { Button, Icon, OverlaysProvider, Tooltip } from "@blueprintjs/core"
import SearchPopover from "./search/SearchPopover"
import RoomLoading from "./RoomLoading"
import { useAppDispatch, useAppSelector } from "../reducers/hooks"
import {
  hideGlobalSearch,
  isGlobalSearchVisible,
  showGlobalSearch,
} from "../reducers/overlaysSlice"
import { isMobile } from "../utils/detectDevice"
import { mobileRoutes, setMobileRoute } from "../routes/routes"

type ChatListHeaderProps = {
  onOpenChatInfo: () => void
  onActiveChatChange: (chatJid: string) => void
}

const ChatListHeader = ({
  onOpenChatInfo,
  onActiveChatChange,
}: ChatListHeaderProps) => {
  const { client, myJid } = useContext(XmppContext)
  const dispatch = useAppDispatch()

  const searchVisible = useAppSelector(isGlobalSearchVisible)

  const handleShowSearch = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      // @ts-ignore
      e.currentTarget?.blur?.()
      dispatch(showGlobalSearch())
      if (isMobile) {
        setMobileRoute(mobileRoutes.SEARCH)
      }
    },
    [dispatch],
  )

  const handleCloseSearch = useCallback(() => {
    if (isMobile) {
      setMobileRoute(mobileRoutes.CHAT_LIST)
    }
    dispatch(hideGlobalSearch())
  }, [dispatch])

  return (
    <div className={css.chatListHeader}>
      <div className="flex flex-row space-x-2">
        <button className={css.user} onClick={onOpenChatInfo}>
          <span
            className={classNames(css.status, {
              [css.offline]: client.status !== "online",
            })}
          ></span>
          &nbsp;
          <b className={cssUtils.bold}>{myJid.getLocal()}</b>
        </button>
        <RoomLoading />
      </div>
      <div className="flex flex-row justify-items-end">
        <OverlaysProvider>
          <Tooltip content="Search in messages" position="left">
            <Button
              data-testid="global-search-button"
              minimal
              icon={<Icon icon="search" size={18} />}
              onClick={handleShowSearch}
            />
          </Tooltip>

          <SearchPopover isOpen={searchVisible} onClose={handleCloseSearch} />
        </OverlaysProvider>
        <JoinChatPopover onActiveChatChange={onActiveChatChange} />
      </div>
    </div>
  )
}

export default ChatListHeader
