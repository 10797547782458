import { Participant } from "../reducers/chatsSlice"
import React from "react"
import { Route2g } from "../reducers/routes2gSlice"

export type DriverContext = {
  driver: Participant | undefined
  setDriverNextDestination: (
    address: string,
    driver: Participant,
  ) => void | undefined
  cancelDestination: (driver: Participant) => void | undefined
  upsertRoute2g: (route: Route2g, driver: Participant) => void | undefined
  addRouteStop: (
    address: string,
    driver: Participant,
    msgId: string,
  ) => void | undefined
}

export const DriverContext = React.createContext({} as DriverContext)
