import React from "react"
import { Icon } from "@blueprintjs/core"

interface ImageShareButtonProps {
  hasFileBeenShared: boolean
  handleShareLink: (downloadFileName: string) => void
  downloadFileName: string
}

// We are not using the Tag component from Blueprint directly because
// when it has labels and an icon, it interferes with the zooming animation
// of the photo. Instead, we're implementing a custom tag-like component
// that doesn't disrupt the zooming functionality.

const ImageShareButton: React.FC<ImageShareButtonProps> = ({
  hasFileBeenShared,
  handleShareLink,
  downloadFileName,
}) => {
  return (
    <a
      href="#"
      title={hasFileBeenShared ? "File has been shared" : "Share file"}
      onClick={(e) => {
        e.preventDefault()
        handleShareLink(downloadFileName)
      }}
    >
      <span className="bp5-tag bp5-intent-primary bp5-large bp5-round">
        <span className="bp5-text-overflow-ellipsis bp5-fill">
          <div className="flex flex-row gap-x-1">
            {hasFileBeenShared && (
              <img className="h-4 mt-0.5" src="/group.svg" alt="Shared" />
            )}
            <span>Share</span>
          </div>
        </span>
        <Icon icon="link" />
      </span>
    </a>
  )
}

export default ImageShareButton
