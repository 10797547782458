import React, {
  useCallback,
  useMemo,
  useState,
  memo,
  useRef,
  useEffect,
} from "react"

import css from "./RouteView.module.css"
import { Resizable, ResizeCallbackData, ResizeHandle } from "react-resizable"

import cssUtils from "./utilsCss.module.css"
import classNames from "classnames"
import RouteStopList from "./RouteStopList"
import { Icon, Tooltip } from "@blueprintjs/core"
import { Location } from "../reducers/locationsSlice"
import { isStopActive, Route2g, RouteStop } from "../reducers/routes2gSlice"
import { GoogleMapsProvider } from "./GoogleMaps"

const MIN_CONSTRAINS = [480, 300] as [number, number]
const RESIZE_HANDLES = ["sw"] as ResizeHandle[]
const HANDLE_SIZE = [15, 15] as [number, number]

const defaultSize = () => {
  let width = (document.documentElement.clientWidth * 40) / 100
  width = width > 650 ? 650 : width
  let height = (document.documentElement.clientHeight * 60) / 100
  height = height > 900 ? 900 : height

  return { width, height }
}

const savedSize = () => {
  const width = parseInt(localStorage.getItem("mapWidth") || "0")
  const height = parseInt(localStorage.getItem("mapHeight") || "0")

  if (width === 0 || height === 0) return null

  return { width, height }
}

type DynamicScrollContainerProps = {
  children: React.ReactNode
  rowHeight: number
}

const ScrollContainer = ({
  children,
  rowHeight,
}: DynamicScrollContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [containerHeight, setContainerHeight] = useState("auto")
  const [showBlur, setShowBlur] = useState(false)

  useEffect(() => {
    const currentContainer = containerRef.current
    if (!currentContainer) return

    const updateDimensions = (entries: ResizeObserverEntry[]) => {
      const entry = entries[0]
      if (entry && currentContainer.parentElement) {
        const parentHeight = currentContainer.parentElement.offsetHeight
        setContainerHeight(`${parentHeight * 0.5 - 8}px`)
      }
    }

    const resizeObserver = new ResizeObserver(updateDimensions)
    const parentElement = currentContainer.parentElement
    if (parentElement) {
      resizeObserver.observe(parentElement)
    }

    return () => resizeObserver.disconnect()
  }, [])

  useEffect(() => {
    const content = contentRef.current
    if (!content) return

    const checkScroll = () => {
      const hasVerticalScroll = content.scrollHeight > content.clientHeight
      const isSingleRow = content.scrollHeight <= rowHeight
      setShowBlur(hasVerticalScroll && !isSingleRow)
    }

    checkScroll()

    const resizeObserver = new ResizeObserver(checkScroll)
    resizeObserver.observe(content)

    return () => resizeObserver.disconnect()
  }, [rowHeight])

  return (
    <div className="relative py-4 px-2" ref={containerRef}>
      <div
        ref={contentRef}
        className="overflow-y-auto"
        style={{ maxHeight: containerHeight }}
      >
        {children}
      </div>
      {showBlur && (
        <div className="absolute left-0 right-4 bottom-[42px] h-[30px] bg-gradient-to-t from-white to-transparent pointer-events-none mr-1" />
      )}
    </div>
  )
}

type RouteViewProps = {
  location: Location
  route?: Route2g
}
const RouteView = ({ location, route }: RouteViewProps) => {
  const [size, setSize] = useState<{ width: number; height: number }>(
    savedSize() || defaultSize(),
  )
  const [resizing, setResizing] = useState<boolean>(false)

  const [showAllStops, setShowAllStops] = useState<boolean>(true)

  const getWaypoints = (activeStops: RouteStop[]) => {
    if (activeStops && activeStops.length > 1) {
      return activeStops
        .slice(0, -1)
        .map((stop) => stop.location.lat + "," + stop.location.lon)
    }
    return undefined
  }

  const mapUrl = useMemo(() => {
    const activeStops = route?.stops?.filter((stop) => isStopActive(stop))

    if (!activeStops?.length) {
      return `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOGLE_MAP_KEY}&q=${location.lat}%2C${location.lon}&zoom=10`
    }

    const origin = location
    const destination = showAllStops
      ? activeStops.at(-1)?.location
      : activeStops[0].location
    const waypoints = getWaypoints(activeStops)

    return `https://www.google.com/maps/embed/v1/directions?key=${process.env.REACT_APP_GOGLE_MAP_KEY}&origin=${origin.lat}%2C${origin.lon}&destination=${destination?.lat}%2C${destination?.lon}&mode=driving&units=metric${showAllStops && waypoints ? "&waypoints=" + waypoints.join("|") : ""}`
  }, [location, route, showAllStops])

  const onResize = useCallback(
    (_: React.SyntheticEvent, data: ResizeCallbackData) => {
      setSize(data.size)
    },
    [],
  )

  const onResizeStop = useCallback(() => {
    setResizing(false)
    localStorage.setItem("mapWidth", size.width.toString())
    localStorage.setItem("mapHeight", size.height.toString())
  }, [size.width, size.height])

  const onResizeStart = useCallback(() => setResizing(true), [])

  return (
    <GoogleMapsProvider>
      <Resizable
        minConstraints={MIN_CONSTRAINS}
        height={size.height}
        width={size.width}
        onResize={onResize}
        resizeHandles={RESIZE_HANDLES}
        onResizeStart={onResizeStart}
        onResizeStop={onResizeStop}
        handleSize={HANDLE_SIZE}
      >
        <div
          className={css.map}
          style={{ width: size.width + "px", height: size.height + "px" }}
        >
          <iframe
            width="100%"
            height="100%"
            referrerPolicy="no-referrer-when-downgrade"
            src={mapUrl}
            allowFullScreen
          ></iframe>
          <div
            className={classNames(css.overlay, {
              [cssUtils.visible]: resizing,
            })}
          ></div>
          <div className={css.mapSwitchContainer}>
            {showAllStops ? (
              <Tooltip
                content="Show first destination only"
                hoverOpenDelay={1000}
              >
                <Icon
                  icon="map-marker"
                  className={css.mapSwitch}
                  size={22}
                  onClick={() => setShowAllStops(false)}
                />
              </Tooltip>
            ) : (
              <Tooltip content="Show all destinations" hoverOpenDelay={1000}>
                <Icon
                  icon="route"
                  className={css.mapSwitch}
                  size={22}
                  onClick={() => setShowAllStops(true)}
                />
              </Tooltip>
            )}
          </div>
          <ScrollContainer rowHeight={34}>
            <RouteStopList
              routeStops={route?.stops}
              currentLocation={location}
            />
          </ScrollContainer>
        </div>
      </Resizable>
    </GoogleMapsProvider>
  )
}

export default memo(RouteView)
