export const mobileRoutes = {
  CHAT: "#chat",
  CHAT_LIST: "#chatList",
  GALLERY: "#gallery",
  PDF: "#pdf",
  SEARCH: "#search",
} as const

export type MobileRoute = (typeof mobileRoutes)[keyof typeof mobileRoutes]

export const setMobileRoute = (route: MobileRoute) =>
  window.history.pushState({}, "", route)

export const updateMobileRoute = (route: MobileRoute) =>
  window.history.replaceState({}, "", route)
