import { isSameDay, parseISO, startOfDay } from "date-fns"
import { MessageGroup, type Message } from "../../../reducers/chatsSlice"

export const areMessageFromDifferentDay = (
  message1: Message | MessageGroup,
  message2: Message | MessageGroup,
) =>
  !isSameDay(getCurrentMessageDate(message1), getCurrentMessageDate(message2))

export const getCurrentMessageDate = (
  messageOrGroup: Message | MessageGroup,
) => {
  const timestamp =
    "timestamp" in messageOrGroup
      ? messageOrGroup.timestamp
      : messageOrGroup.messages[0].timestamp

  return startOfDay(parseISO(timestamp))
}
