import { useCallback, useContext } from "react"
import { XmppContext } from "../stream/xmppClient"
import { useAppSelector } from "../reducers/hooks"
import {
  selectActiveChat,
  selectMessageChat,
} from "../reducers/chatsSliceSelectors"
import AdHocCommands from "../api/adHocCommands"
import FileShareResultParser from "../lib/fileShareResultParser"
import ClipboardWriter from "../lib/clipboardWriter"
import { Message } from "../reducers/chatsSlice"
import { format } from "date-fns"

const useHandleShareLink = (message: Message) => {
  const { myJid, client } = useContext(XmppContext)
  const activeChat = useAppSelector(selectActiveChat)
  const messageChat = useAppSelector(
    selectMessageChat(message, myJid.toString()),
  )

  return useCallback(
    (filename: string) =>
      ClipboardWriter.executeAndWriteResult(async () => {
        window.analytics.track("ShareFileLinkGenerated", {
          userJid: myJid.toString(),
          messageId: message.id,
          fileUrl: message.url,
          chatJid: activeChat.jid,
        })

        if (message.url) {
          const response = await AdHocCommands.shareFile({
            client,
            fileUrl: message.url,
            messageId: message.id,
            messageRoomJid: messageChat?.jid,
            triggeringRoomJid: activeChat.jid,
            userJid: myJid.toString(),
          })

          const fileShareResult = FileShareResultParser.parse(response)
          if (fileShareResult?.shareToken) {
            window.appToaster.show({
              message: "Link copied to clipboard",
              intent: "success",
            })
          } else {
            window.appToaster.show({
              message:
                "Sorry, but we could not share this file. Please try again later.",
              intent: "danger",
            })
          }

          const formattedDate = format(new Date(), "yyyy-MM-dd")
          const extension = filename.split(".").pop()

          filename = `${formattedDate}.${extension}`

          return `${process.env.REACT_APP_FILES_ENDPOINT}/${fileShareResult?.shareToken}/${filename}`
        }
      }),
    [message, client, myJid, activeChat, messageChat],
  )
}

export default useHandleShareLink
