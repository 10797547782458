import { getCompletenessPayload } from "../reducers/chatsSliceSelectors"
import posthog from "posthog-js"

const INTERVAL = 60 * 60

let worker: number | null = null

const report = () => {
  if (!posthog.isFeatureEnabled("completeness-reporting")) {
    console.debug("Completeness skipped")
    return
  }

  console.debug(`Completeness run: ${worker}`)

  const payload = getCompletenessPayload(window.store.getState())
  if (payload) {
    window.analytics.track("CompletenessReport", payload)
  }
  console.debug("CompletenessReport", payload)
}

export const runReporting = () => {
  worker = window.setInterval(report, 1000 * INTERVAL)
  report()
}
