// Polyfill for Promise.withResolvers
// This is needed because the pdfjs-dist library requires it,
// but it's not available in all JavaScript environments.

Promise.withResolvers ||
  (Promise.withResolvers = function withResolvers() {
    let a,
      b,
      c = new this(function (resolve, reject) {
        a = resolve
        b = reject
      })
    return { resolve: a, reject: b, promise: c }
  })

export {}
