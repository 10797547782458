import classNames from "classnames"
import React, { memo, useMemo, useCallback } from "react"
import {
  isMobile,
  isWindows,
  isMacOs,
  isSafari,
  browserVersion,
} from "../../utils/detectDevice"
import FileToolbar from "./FileToolbar"
import { Message } from "../../reducers/chatsSlice"
import { useAppDispatch } from "../../reducers/hooks"
import { updatePDFViewer } from "../../reducers/overlaysSlice"
import { mobileRoutes, setMobileRoute } from "../../routes/routes"
import SignedCMRTag from "./SignedCMRTag"

type PDFFileProps = {
  url: string
  message?: Message
  fileName: string
  inGroup?: boolean
  fromSearch?: boolean
}

const PDFFile = memo(
  ({ url, message, inGroup, fileName, fromSearch = false }: PDFFileProps) => {
    const dispatch = useAppDispatch()
    const signedCMR =
      message?.documentData?.signed && message?.documentData?.type === "CMR"
    const handlePDFClick = useCallback(
      (event: React.MouseEvent) => {
        const isOldSafari = isSafari && parseInt(browserVersion, 10) < 17
        const isCtrlOrCmdClick =
          event.button === 0 &&
          ((isWindows && event.ctrlKey) || (isMacOs && event.metaKey))
        const isMiddleClick = event.button === 1
        if (isCtrlOrCmdClick || isMiddleClick || fromSearch || isOldSafari) {
          window.open(url)
        } else {
          dispatch(
            updatePDFViewer({
              visible: true,
              PDFMessage: message,
            }),
          )
          if (isMobile) {
            setMobileRoute(mobileRoutes.PDF)
          }
        }
      },
      [message, url, dispatch, fromSearch],
    )

    const thumbnail = message?.thumbnail
    if (thumbnail) {
      const className = classNames(
        "cursor-pointer max-h-full max-w-full object-scale-down",
        {
          "w-28 h-28 rounded-lg": inGroup,
          "object-center": !inGroup,
        },
      )

      return (
        <>
          <img
            data-testid="pdf-file"
            width={inGroup ? undefined : thumbnail.width}
            height={inGroup ? undefined : thumbnail.height}
            src={thumbnail.uri}
            className={className}
            onMouseDown={handlePDFClick}
          />
          <img src="pdf.svg" className="w-7 h-7 absolute left-2 top-2" />
          {signedCMR && <SignedCMRTag inGroup={inGroup} />}
        </>
      )
    }
    return (
      <>
        <img
          data-testid="pdf-file"
          src="pdf.svg"
          className="w-10 h-10 cursor-pointer"
          onMouseDown={handlePDFClick}
        />
        <span
          className={classNames(
            "break-words text-clip line-clamp-2",
            inGroup && "max-w-20",
          )}
        >
          {fileName}
        </span>
      </>
    )
  },
)

export type FileStubProps = {
  url: string
  name?: string
  inGroup?: boolean
  message?: Message
  nonClickable?: boolean
  naked?: boolean
  withoutFileToolbar?: boolean
  fromSearch?: boolean
}

const FileStub = ({
  url,
  name,
  inGroup,
  message,
  nonClickable,
  naked = false,
  withoutFileToolbar = false,
  fromSearch = false,
}: FileStubProps) => {
  const isPDF = url?.endsWith("pdf")

  const fileName = useMemo(() => {
    if (name) return name
    const nameStart = url.lastIndexOf("/") + 1
    return url.slice(nameStart, url.length)
  }, [url, name])

  const className = inGroup
    ? "group flex items-center relative"
    : "group flex items-center relative min-h-40 min-w-40"

  return (
    <div className={className}>
      <div className="flex flex-col justify-center items-center gap-y-2 text-center text-xs h-full w-full">
        {!inGroup && !naked && message && !withoutFileToolbar && (
          <FileToolbar url={url} message={message} isMobile={isMobile} />
        )}
        <div className="flex flex-col justify-center items-center gap-y-2 text-center text-xs h-full w-full">
          {nonClickable && !isPDF && (
            <span
              className={classNames(
                "break-words text-clip line-clamp-2",
                inGroup && "max-w-20",
              )}
            >
              {fileName}
            </span>
          )}
          {isPDF && (
            <PDFFile
              url={url}
              message={message}
              inGroup={inGroup}
              fileName={fileName}
              fromSearch={fromSearch}
            />
          )}
          {!nonClickable && !isPDF && (
            <a href={url} target="_blank" rel="noreferrer">
              <span
                className={classNames(
                  "break-words text-clip line-clamp-2",
                  inGroup && "max-w-20",
                )}
              >
                {fileName}
              </span>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(FileStub)
