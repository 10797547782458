import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  memo,
  useMemo,
} from "react"
import { Button, Tooltip } from "@blueprintjs/core"
import RouteStopView, { LocationEditView } from "./RouteStopView"
import { DriverContext } from "../contexts/DriverContext"
import {
  findCurrentTarget,
  isStopActive,
  isStopLate,
  isStopPending,
  RouteStop,
} from "../reducers/routes2gSlice"
import { Locate, Plus } from "lucide-react"
import { Location } from "../reducers/locationsSlice"
import { Address, useGoogleMaps } from "./GoogleMaps"
import { captureError } from "../ErrorHandlers"

const ConnectingLine = ({ solid }: { solid: boolean }) => (
  <tr className="[&_td]:p-px">
    <td></td>
    <td className="relative">
      <div className="absolute inset-0 flex items-center justify-center">
        <div
          className={`h-[10px] w-px border-gray-600 border-l-2 ${solid ? "border-solid" : "border-dotted"}`}
        />
      </div>
    </td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
)

type CurrentLocationProps = {
  location: Location
}
const CurrentLocation = ({ location }: CurrentLocationProps) => {
  const [address, setAddress] = useState<Address | null>(null)
  const { reverseGeocode } = useGoogleMaps()

  useEffect(() => {
    const fetchAddress = async () => {
      if (!location.lat || !location.lon) return
      try {
        const result = await reverseGeocode(location.lat, location.lon)
        setAddress(result)
      } catch (err) {
        captureError("Error", {
          origin: "UserAction",
          extra: {
            message:
              "Reverse geocoding of driver's current location with google maps",
          },
        })
      }
    }

    void fetchAddress()
  }, [location, reverseGeocode])

  const shortAddress = useMemo(
    () => (address ? `${address.countryCode}, ${address.city}` : null),
    [address],
  )

  if (!address || !shortAddress) return null

  return (
    <tr>
      <td className="w-[23px]"></td>
      <td className="w-px">
        <Tooltip content="Current location">
          <div className="min-w-[30px] min-h-[30px] px-[10px] py-[5px] mb-[1px] flex items-center">
            <Locate size={16} />
          </div>
        </Tooltip>
      </td>
      <td>
        <Tooltip content={`Current location: ${address.formattedAddress}`}>
          <div className="px-[10px]">{shortAddress}</div>
        </Tooltip>
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  )
}

type NewStopViewProps = {
  onAdd: (stop: RouteStop) => void
}

const NewStopView = ({ onAdd }: NewStopViewProps) => {
  const [isAdding, setIsAdding] = useState(false)

  const addStop = useCallback(
    (location: Location) => {
      setIsAdding(false)
      const newStop = { location: location }
      onAdd(newStop)
    },
    [onAdd],
  )

  const abortAddStop = () => {
    setIsAdding(false)
  }

  return (
    <tr className="group">
      <td></td>
      <td>
        <Button
          minimal={true}
          disabled={isAdding}
          onClick={() => setIsAdding(true)}
          className="group-hover:bg-[#E9EBEE]" // blueprint.js hover color
        >
          <Plus size={16} />
        </Button>
      </td>
      <td>
        {isAdding ? (
          <div className="">
            <LocationEditView onUpdate={addStop} onAbort={abortAddStop} />
          </div>
        ) : (
          <Button
            minimal={true}
            onClick={() => setIsAdding(true)}
            className="w-full justify-start group-hover:bg-[#E9EBEE]"
          >
            <span className="font-bold text-s text-gray-500 w-full">
              Add new stop
            </span>
          </Button>
        )}
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  )
}

type RouteStopListProps = {
  currentLocation: Location
  routeStops?: RouteStop[]
}

const RouteStopList = ({ currentLocation, routeStops }: RouteStopListProps) => {
  const [stops, setStops] = useState<RouteStop[]>([])
  const [moveCompleted, setMoveCompleted] = useState(false)

  const { upsertRoute2g, driver } = useContext(DriverContext)

  useEffect(() => {
    if (routeStops) {
      const enrichedStops = routeStops.map((stop) => {
        const currentTarget = findCurrentTarget(routeStops)
        return {
          ...stop,
          is_active: isStopActive(stop),
          is_current_target: currentTarget === stop,
          is_late: isStopLate(stop),
        }
      })
      setStops(enrichedStops)
    } else {
      setStops([])
    }
  }, [routeStops])

  useEffect(() => {
    if (!driver) return
    if (!moveCompleted) return

    setMoveCompleted(false)
    upsertRoute2g({ stops: stops, jid: driver.jid }, driver)
  }, [moveCompleted, setMoveCompleted, stops, driver, upsertRoute2g])

  const updateStop = useCallback(
    (stop: RouteStop, type: string) => {
      if (!driver) return
      const updated = stops.map((s) => (s.id === stop.id ? stop : s))
      setStops(updated)
      upsertRoute2g({ stops: updated, jid: driver.jid }, driver)
      window.analytics.track("UpdateRouteStop", {
        id: stop.id,
        addedBy: stop.by,
        updateType: type,
      })
    },
    [stops, driver, upsertRoute2g],
  )

  const addStop = useCallback(
    (stop: RouteStop) => {
      if (!driver) return
      const updated = [...stops, stop]
      const index = stops.filter((stop) => isStopPending(stop)).length
      setStops(updated)
      upsertRoute2g({ stops: updated, jid: driver.jid }, driver)
      window.analytics.track("AddRouteStop", {
        source: "list",
        index: index,
        multi: index > 0,
      })
    },
    [stops, driver, upsertRoute2g],
  )

  const removeStop = useCallback(
    (stop: RouteStop) => {
      if (!driver) return
      const updated = stops.filter((s) => s.id !== stop.id)
      setStops(updated)
      upsertRoute2g({ stops: updated, jid: driver.jid }, driver)
      window.analytics.track("RemoveRouteStop", {
        id: stop.id,
        addedBy: stop.by,
      })
    },
    [stops, driver, upsertRoute2g],
  )

  const moveStop = useCallback(
    (from: number, to: number) => {
      const updatedStops = [...stops]
      const [movedStop] = updatedStops.splice(from, 1)
      updatedStops.splice(to, 0, movedStop)
      setStops(updatedStops)
    },
    [stops],
  )

  const shouldDisplayCurrentLocation = () => {
    return (
      !routeStops ||
      routeStops.length === 0 ||
      (routeStops[0].proximity_status &&
        ["arriving", "far"].includes(routeStops[0].proximity_status))
    )
  }

  const Stop = ({ stop, index }: { stop: RouteStop; index: number }) => {
    const nextStop = index < stops.length - 1 ? stops[index + 1] : null
    const isNextStopCurrentTarget = Boolean(nextStop?.is_current_target)
    return (
      <>
        <RouteStopView
          index={index}
          stop={stop}
          onUpdate={updateStop}
          onRemove={removeStop}
          onMove={moveStop}
          onMoveCompleted={() => {
            setMoveCompleted(true)
          }}
        />
        {nextStop && <ConnectingLine solid={isNextStopCurrentTarget} />}
      </>
    )
  }

  return (
    <table className="w-full [&_td]:p-[3px]">
      <tbody>
        {shouldDisplayCurrentLocation() && (
          <>
            <CurrentLocation location={currentLocation} />
            {stops.length > 0 && <ConnectingLine solid={true} />}
          </>
        )}
        {stops.map((stop, index) => (
          <Stop stop={stop} index={index} key={index} />
        ))}
      </tbody>
      <tfoot className="sticky inset-0 bg-white w-full">
        <NewStopView onAdd={addStop} />
      </tfoot>
    </table>
  )
}

export default memo(RouteStopList)
