import React, { useEffect, useCallback, useRef, useState } from "react"
import type { PageViewport, PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist"
import { PhotoSlider } from "react-photo-view"
import { isMobile, isSafari } from "../../utils/detectDevice"
import Loader from "../Loader"
import { PDFTopToolbar } from "./PDFTopToolbar"
import { PDFBottomToolbar } from "./PDFBottomToolbar"
import "../../polyfills/withResolvers"
import { hidePDFViewer, selectPDFViewer } from "../../reducers/overlaysSlice"
import { useAppDispatch, useAppSelector } from "../../reducers/hooks"
import { mobileRoutes, setMobileRoute } from "../../routes/routes"

const getPageWithViewport = async (
  pdfDoc: PDFDocumentProxy,
  pageNumber: number,
) => {
  const page = await pdfDoc.getPage(pageNumber)

  // Get the original viewport of the PDF page at scale 1
  const originalViewport = page.getViewport({ scale: 1 })

  // Determine if the device is in portrait orientation
  const isPortrait = window.innerWidth < window.innerHeight

  // Calculate the scale factor based on device orientation
  // For portrait: scale to fit width
  // For landscape: scale to fit height
  const scale = isPortrait
    ? window.innerWidth / originalViewport.width
    : window.innerHeight / originalViewport.height

  // Create a new viewport with the calculated scale
  const viewport = page.getViewport({ scale })

  // Return the page and its scaled viewport
  return { page, viewport }
}

type PDFPageProps = {
  page: PDFPageProxy
  viewport: PageViewport
  scale: number
  attrs: {
    style: { width: string }
    className?: string
  }
}

const pageRenderer = (page: PDFPageProxy, viewport: PageViewport) => {
  return ({
    scale,
    attrs,
  }: {
    scale: number
    attrs: PDFPageProps["attrs"]
  }) => <PDFPage page={page} viewport={viewport} scale={scale} attrs={attrs} />
}

const PDFPage: React.FC<PDFPageProps> = ({ page, attrs }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  // Get the normal viewport at scale 1
  const normalViewport = page.getViewport({ scale: 1 })

  // Extract width from style attributes
  const width = parseInt(attrs.style.width, 10)

  // Calculate scale based on desired width
  const scale = width / normalViewport.width

  // Create a new viewport with the calculated scale
  const viewport = page.getViewport({ scale })

  // Reference to keep track of the current render task
  const renderTaskRef = useRef<any>(null)

  // Function to render the PDF page
  const renderPage = useCallback(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const canvasContext = canvas.getContext("2d")
    if (!canvasContext) return

    // Cancel any ongoing render task
    if (renderTaskRef.current) {
      renderTaskRef.current.cancel()
    }

    // Start a new render task
    const renderTask = page.render({
      canvasContext,
      viewport,
    })

    renderTaskRef.current = renderTask

    // Handle render task completion
    renderTask.promise.then(
      () => {
        renderTaskRef.current = null
      },
      () => {
        renderTaskRef.current = null
      },
    )
  }, [page, viewport])

  // Effect to trigger page rendering and cleanup
  useEffect(() => {
    renderPage()

    // Cleanup function to cancel render task on unmount
    return () => {
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel()
      }
    }
  }, [renderPage])

  // Render the canvas element
  return (
    <div {...attrs} className={`flex-none bg-white ${attrs.className || ""}`}>
      <canvas ref={canvasRef} width={viewport.width} height={viewport.height} />
    </div>
  )
}

const PDFViewer = () => {
  const dispatch = useAppDispatch()
  const { visible, PDFMessage } = useAppSelector(selectPDFViewer)
  const onClose = useCallback(() => {
    dispatch(hidePDFViewer())
    if (isMobile) {
      setMobileRoute(mobileRoutes.CHAT)
    }
  }, [dispatch])
  const [isPDFLoaded, setIsPDFLoaded] = useState<boolean>(false)
  const [index, setIndex] = useState(0)
  // State to store PDF pages
  // initially we are passing a loader
  const [pages, setPages] = useState([
    {
      key: 1,
      render: () => <Loader />,
      width: 100,
      height: 100,
    },
  ] as any)

  // State to store PDF.js library as we are importing it dynamically
  const [pdfjsLib, setPdfjsLib] = useState<any>(null)

  // Dynamically import PDF.js library to reduce bundle size
  useEffect(() => {
    const loadPDFLib = async () => {
      const pdfjsLib = await import("pdfjs-dist")

      if (isSafari || window.Cypress) {
        // For Safari we need to use modified worker with polyfills
        pdfjsLib.GlobalWorkerOptions.workerSrc = `${window.location.origin}/pdf.worker.min.mjs`
      } else {
        // Set the worker source for PDF.js based on our pdfjs-dist version
        pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.mjs`
      }
      setPdfjsLib(pdfjsLib)
    }
    void loadPDFLib()
  }, [])

  // Effect to load and process PDF document
  useEffect(() => {
    if (pdfjsLib) {
      pdfjsLib.getDocument(PDFMessage?.url).promise.then(function (
        pdfDoc: PDFDocumentProxy,
      ) {
        const pagesPromises = []
        // Create promises for each page
        for (let i = 1; i <= pdfDoc.numPages; i++) {
          pagesPromises.push(getPageWithViewport(pdfDoc, i))
        }
        // Resolve all page promises
        void Promise.all(pagesPromises).then((resolvedPagesWitPromises) => {
          // Map resolved pages to required format
          const pages = resolvedPagesWitPromises.map(
            ({ page, viewport }, index: number) => ({
              key: index + 1,
              render: pageRenderer(page, viewport),
              width: viewport.width,
              height: viewport.height,
            }),
          )
          setPages(pages)
          setIsPDFLoaded(true)
        })
      })
    }
  }, [PDFMessage?.url, pdfjsLib])

  return (
    <PhotoSlider
      loop={isPDFLoaded}
      // Custom z-index to ensure proper layering with toasts
      className="!z-30"
      images={pages}
      visible={visible}
      onClose={onClose}
      index={index}
      onIndexChange={setIndex}
      bannerVisible={isPDFLoaded}
      // Render custom top toolbar if PDF is loaded
      toolbarRender={
        isPDFLoaded
          ? (props) => <PDFTopToolbar {...props} onIndexChange={setIndex} />
          : undefined
      }
      // Render custom bottom toolbar if PDF is loaded
      overlayRender={
        isPDFLoaded
          ? (props) => (
              <PDFBottomToolbar
                {...props}
                totalPages={pages.length}
                PDFMessage={PDFMessage!}
                index={index}
                onIndexChange={setIndex}
              />
            )
          : undefined
      }
    />
  )
}

export default PDFViewer
