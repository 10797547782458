import React, { KeyboardEvent, useState } from "react"
import { Label, InputGroup, Button, ControlGroup } from "@blueprintjs/core"
import { useForm } from "react-hook-form"

import formsCss from "./formsCss.module.css"

type SignInFormProps = {
  onSubmit: (userAuth: Types.Api.UserAuth) => void
}

const SignInForm = ({ onSubmit }: SignInFormProps) => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)
  const { register, getValues } = useForm<Types.Api.UserAuth>({
    mode: "all",
  })

  const emailField = register("email", { required: "Email is required" })
  const passwordField = register("password", {
    required: "Password is required",
  })

  const onFormSubmit = () => {
    const values = getValues()
    onSubmit({
      email: values.email.toLowerCase().trim(),
      password: values.password.trim(),
    })
  }

  const handleOnEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onFormSubmit()
    }
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <div>
      <div className={formsCss.fieldsRow}>
        <ControlGroup fill>
          <Label>
            Email
            <InputGroup
              data-testid="login"
              autoFocus={true}
              name={emailField.name}
              onChange={emailField.onChange}
              onBlur={emailField.onBlur}
              inputRef={emailField.ref}
              aria-label={emailField.name}
              large
            />
          </Label>
        </ControlGroup>
      </div>
      <div className={formsCss.fieldsRow}>
        <ControlGroup fill>
          <Label>
            Password
            <InputGroup
              data-testid="password"
              name={passwordField.name}
              onChange={passwordField.onChange}
              onBlur={passwordField.onBlur}
              inputRef={passwordField.ref}
              aria-label={passwordField.name}
              type={passwordVisible ? "text" : "password"}
              large
              onKeyDown={handleOnEnter}
              rightElement={
                <Button
                  minimal
                  icon={passwordVisible ? "eye-open" : "eye-off"}
                  onClick={togglePasswordVisibility}
                />
              }
            />
          </Label>
        </ControlGroup>
      </div>
      <div className={formsCss.actions}>
        <Button
          data-testid="submit"
          large
          intent="success"
          onClick={onFormSubmit}
          text="Sign in"
        />
      </div>
    </div>
  )
}

export default SignInForm
