import React from "react"
import type { OverlayRenderProps } from "react-photo-view/dist/types"
import { isMobile, isIOS } from "../../utils/detectDevice"
import { Tag } from "@blueprintjs/core"
import type { Message } from "../../reducers/chatsSlice"
import useHasFileBeenShared from "../../hooks/useHasFileBeenShared"
import useHandleDownload from "../../hooks/useHandleDownload"
import useHandleShareLink from "../../hooks/useHandleShareLink"
import useDownloadFileName from "../../hooks/useDownloadFileName"
import ImageShareButton from "./ImageShareButton"
import classNames from "classnames"
import MessageHelpers from "../../lib/messageHelpers"

export const ImageBottomToolbar = ({ images, index }: OverlayRenderProps) => {
  const image = images[index]
  // @ts-ignore
  const message = image.message as Message
  const url = image.src!
  const hasFileBeenShared = useHasFileBeenShared(message)
  const handleShareLink = useHandleShareLink(message)
  const handleDownload = useHandleDownload()
  const downloadFileName = useDownloadFileName(message)

  return (
    <div
      className={classNames(
        "absolute justify-end flex flex-row gap-2 right-0 bottom-0 p-2 w-full text-sm text-slate-300 z-50 bg-black/50",
        { "min-h-20": isIOS },
      )}
    >
      <div
        className="absolute pt-1"
        style={{
          left: isMobile ? "5px" : "50%",
          transform: isMobile ? undefined : "translateX(-50%)",
        }}
      >
        {MessageHelpers.timestamp(message)}
      </div>
      {!isMobile && message && handleDownload && downloadFileName && (
        <a
          href={image.src}
          download
          onClick={(e) => {
            e.preventDefault()
            handleDownload(url, downloadFileName!)
          }}
        >
          <Tag icon="download" round intent="primary" large />
        </a>
      )}
      {isMobile && url && (
        <a href={url} download target="_blank" rel="noreferrer">
          <Tag icon="download" round intent="primary" large />
        </a>
      )}
      {message && handleShareLink && downloadFileName && (
        <ImageShareButton
          hasFileBeenShared={hasFileBeenShared}
          handleShareLink={handleShareLink}
          downloadFileName={downloadFileName}
        />
      )}
    </div>
  )
}
