import React, { useMemo } from "react"
import { Tooltip } from "@blueprintjs/core"
import {
  ACTIONS,
  findCurrentTarget,
  Route2g,
  RouteStop,
} from "../reducers/routes2gSlice"
import { Sparkles } from "lucide-react"

type RouteInfoProps = {
  route?: Route2g
}

const formatStopDistance = (stop: RouteStop): string => {
  if (stop.proximity_status === "arrived") return "Nearby"
  if (stop.proximity_status === "arriving") return "Arriving"
  if (stop.remaining_distance && stop.remaining_distance >= 1000) {
    return `${Math.floor(stop.remaining_distance / 1000).toString()} km`
  } else if (stop.remaining_distance) {
    return `${stop.remaining_distance} m`
  }
  return ""
}

const RouteInfo = ({ route }: RouteInfoProps) => {
  const stops = route?.stops

  const displayedStop = useMemo(() => {
    return findCurrentTarget(stops)
  }, [stops])

  if (!stops?.length) return null

  const Alert = () => {
    if (stops.some((stop: RouteStop) => stop?.timeliness_status === "late")) {
      return (
        <Tooltip content="Some of the scheduled dates will not be met">
          <div className="w-3 h-3 rounded-full bg-red-500 mr-4"></div>
        </Tooltip>
      )
    }
    return null
  }

  const StopAction = ({ stop }: { stop: RouteStop }) => {
    const action =
      ACTIONS.find((action) => action.value.toLowerCase() === stop.action) ??
      ACTIONS.find((action) => action.value === "other")

    if (!action) {
      console.error("No matching action found for stop")
      return null
    }

    if (action.value === "other") {
      return null
    }

    return (
      <Tooltip content={`Next stop is ${action.value}`}>
        <div className="flex gap-0">
          <action.icon size={15} />
          {stop.by?.startsWith("assistant") && <Sparkles size={10} />}
        </div>
      </Tooltip>
    )
  }

  return (
    <div className="flex gap-1 items-center">
      <div className="flex items-center">
        <Alert />

        {displayedStop && <StopAction stop={displayedStop} />}
      </div>
      {displayedStop && (
        <div className="flex items-center">
          <Tooltip content="Distance to next destination">
            {formatStopDistance(displayedStop)}
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default RouteInfo
