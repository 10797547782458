import { useCallback } from "react"
import { saveAs } from "file-saver"

const useHandleDownload = () => {
  return useCallback((url: string, fileName: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, fileName)
      })
      .catch((error) => {
        console.error("Error while downloading file:", error)
      })
  }, [])
}

export default useHandleDownload
