import { useCallback, useEffect } from "react"
import { isMobile } from "../utils/detectDevice"
import { mobileRoutes, setMobileRoute } from "../routes/routes"

export function useSelectChatNavigation(
  onReturnToChatLists: (usingBackButton: boolean) => void,
) {
  const navigateToChat = useCallback(() => {
    if (isMobile) {
      setMobileRoute(mobileRoutes.CHAT)
    }
  }, [])

  useEffect(() => {
    const listener = () => onReturnToChatLists(true)
    window.addEventListener("popstate", listener)
    return () => window.removeEventListener("popstate", listener)
  }, [onReturnToChatLists])

  return { navigateToChat }
}
