import {
  PreloadedState,
  combineReducers,
  configureStore,
  PayloadAction,
} from "@reduxjs/toolkit"
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist-indexeddb-storage"
import nativeStorage from "../lib/ReactNativeStorage"
import chatsReducer from "./chatsSlice"
import chatMetaReducer from "./chatMetaSlice"
import connectionStatusReducer from "./connectionStatusSlice"
import profilesReducer from "./profilesSlice"
import settingsReducer from "./settingsSlice"
import appVersionReducer from "./appVersionSlice"
import subjectsReducer from "./subjectsSlice"
import routesReducer from "./routesSlice"
import routes2gReducer from "./routes2gSlice"
import activityReducer from "./activitySlice"
import chatRepliesReducer from "./chatRepliesSlice"
import roomSyncStatusReducer from "./roomSyncStatusSlice"
import sendoutQueueReducer from "./sendoutQueueSlice"
import monitorEnhancer from "../enhancers/monitorEnhancer"
import roomSyncListener from "../listeners/roomSync"
import rosterReducer from "./rosterSlice"
import locationsReducer from "./locationsSlice"
import overlaysReducer from "./overlaysSlice"

export const STATE_VERSION = 39

const rootReducer = combineReducers({
  chats: chatsReducer,
  chatMeta: chatMetaReducer,
  connectionStatus: connectionStatusReducer,
  profiles: profilesReducer,
  settings: settingsReducer,
  overlays: overlaysReducer,
  appVersion: appVersionReducer,
  subjects: subjectsReducer,
  routes: routesReducer,
  routes2g: routes2gReducer,
  activities: activityReducer,
  chatReplies: chatRepliesReducer,
  roomSyncStatus: roomSyncStatusReducer,
  sendoutQueue: sendoutQueueReducer,
  roster: rosterReducer,
  locations: locationsReducer,
})

const migrator = async (state: any, version: number) => {
  const oldVersion = state?._persist?.version
  console.info(`Store migration, ${oldVersion} => ${version}`)

  // Don't alter store if no version is found for some reason
  if (!oldVersion) {
    return state
  }

  // Don't alter store if "old" version is actually newer or same.
  // This is the main path: `oldVersion === version`
  if (oldVersion >= version) {
    return state
  }

  // Just reset the store fully if old version changed
  // This is the default migration logic
  window.analytics.track("PurgeStore", {
    reason: "state_version_changed",
    oldVersion,
    newVersion: version,
  })
  return {} as any
}

const persistedReducer = persistReducer(
  {
    key: "root",
    storage: window.ReactNativeWebView
      ? nativeStorage(window.ReactNativeWebView)
      : storage("slickshift"),
    throttle: 4000,
    version: STATE_VERSION,
    migrate: migrator,
    blacklist: ["roomSyncStatus", "overlays"],
  },
  rootReducer,
)

const logger = () => (next: any) => (action: PayloadAction) => {
  // for nice debugging of what happens on the store
  // console.log(`dispatching ${JSON.stringify(action)}`)
  // Sentry.startSpan({ name: action.type, op: "redux.dispatch" }, () => {})
  return next(action)
}

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat([logger, roomSyncListener.middleware]),
    enhancers: [monitorEnhancer],
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore["dispatch"]
