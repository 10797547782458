import React from "react"
import { format, isThisWeek, startOfDay } from "date-fns"

type DayMarkerProps = {
  date: Date
}

const DayMarker: React.FC<DayMarkerProps> = ({ date }) => {
  const formatDate = (date: Date) => {
    const startOfDayDate = startOfDay(date)

    if (isThisWeek(startOfDayDate, { weekStartsOn: 1 })) {
      return format(startOfDayDate, "EEEE")
    } else {
      return format(startOfDayDate, "MMM d")
    }
  }

  return (
    <div className="flex justify-center items-center my-4">
      <div className="bg-gray-200 text-gray-700 px-3 py-1 rounded-full text-sm">
        {formatDate(date)}
      </div>
    </div>
  )
}

export default DayMarker
