import { Client, xml } from "@xmpp/client"
import { v4 as uuid } from "uuid"
import { Chat, buildChat } from "../reducers/chatsSlice"
import AdHocCommands from "./adHocCommands"
import iqMeasure from "../instrumentation/iqMeasure"
import MucDiscoInfoParser from "../lib/mucDiscoInfoParser"
import { Element } from "@xmpp/xml"

const XmppGroupChatApi = {
  fetchRoomConfig: async (client: Client, to: string) => {
    const { iqCaller } = client

    const measuredIqCaller = iqMeasure(iqCaller, "room_disco_info")
    const result = await measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "get",
          to: to,
        },
        xml("query", "http://jabber.org/protocol/disco#info"),
      ),
    )

    return MucDiscoInfoParser.parse(result)
  },
  changeSubject: async (client: Client, to: string, subject: string) => {
    const message = xml(
      "message",
      { type: "groupchat", to: to, from: client.jid, id: uuid() },
      xml("subject", {}, subject),
    )

    return client.send(message)
  },
  fetchRooms: async (xmpp: Client): Promise<Chat[]> => {
    const items = await AdHocCommands.getRooms(xmpp)
    return items.map((item) => {
      return buildChat({
        room: true,
        jid: item.jid,
        name: item.name,
        groups: item.groups,
      })
    })
  },

  sendPresence: async (
    xmpp: Client,
    to: string,
    nickname: string,
    requestHistorySince?: Date,
  ): Promise<void> => {
    const presence = xml(
      "presence",
      { to: `${to}/${nickname}`, from: xmpp.jid },
      xml(
        "x",
        "http://jabber.org/protocol/muc",
        xml(
          "history",
          requestHistorySince
            ? { since: requestHistorySince.toISOString() }
            : { maxchars: "0" },
        ),
      ),
    )
    return xmpp.send(presence)
  },

  sendRoomExitPresence: async (
    xmpp: Client,
    to: string,
    nickname: string,
  ): Promise<void> => {
    const presence = xml("presence", {
      to: `${to}/${nickname}`,
      from: xmpp.jid,
      type: "unavailable",
    })
    return xmpp.send(presence)
  },

  sendMessage: async (xmpp: Client, to: string, text: string) => {
    const originId = uuid()
    window.analytics.track("GroupChatSendClick", { originId })
    const messageElements = [
      xml("body", {}, text),
      xml("markable", "urn:xmpp:chat-markers:0"),
      xml("origin-id", { xmlns: "urn:xmpp:sid:0", id: originId }),
    ]
    const message = xml(
      "message",
      { type: "groupchat", to: to, from: xmpp.jid, id: uuid() },
      ...messageElements,
    )

    return xmpp.send(message)
  },

  buildReactionStanza: (
    from: string,
    to: string,
    messageId: string,
    reaction: string | null,
  ): Element => {
    return xml(
      "message",
      { type: "groupchat", to, from: from, id: uuid() },
      xml("store", "urn:xmpp:hints"),
      xml(
        "reactions",
        {
          xmlns: "urn:xmpp:reactions:0",
          id: messageId,
        },
        ...(reaction !== null ? [xml("reaction", {}, reaction)] : []),
      ),
    )
  },
}

export default XmppGroupChatApi
