import "./markLoaded"
import "./loadingMetrics"
import "./instrumentation/sentry"
import "./instrumentation/posthog"
import { runReporting } from "./instrumentation/completeness"

import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

const COMPLETENESS_REPORTING_DELAY = 1000 * 10

setTimeout(runReporting, COMPLETENESS_REPORTING_DELAY)

const appHeight = () => {
  const doc = document.documentElement
  console.info(`Setting --app-height to ${window.innerHeight}px height`)
  doc.style.setProperty("--app-height", `${window.innerHeight}px`)
}
window.addEventListener("resize", appHeight)
appHeight()

// Re-calculate app height due to it being set to zero when running app on
// WebView in ReactNative. I don't know the proper fix, so I'm waiting 500ms.
setTimeout(appHeight, 500)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
