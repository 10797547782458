import { useContext } from "react"
import { useAppSelector } from "../reducers/hooks"
import { messageAuthorName } from "../reducers/profilesSliceSelectors"
import { XmppContext } from "../stream/xmppClient"
import { getDownloadFileName } from "../components/message/utils/getDownloadFileName"
import { Message } from "../reducers/chatsSlice"

export const useDownloadFileName = (message: Message) => {
  const { myJid } = useContext(XmppContext)
  const messageWithAuthor = message.forwarded ? message.forwarded : message
  const fileSenderName = useAppSelector(
    messageAuthorName(messageWithAuthor, myJid),
  )

  return getDownloadFileName(message, fileSenderName)
}

export default useDownloadFileName
