import { Element } from "@xmpp/xml"
import parse from "@xmpp/xml/lib/parse"
import type { AppStore } from "./reducers/store"
import { Client, xml } from "@xmpp/client"
import { getItem } from "./reducers/sendoutQueueSelectors"
import {
  BatchAcker,
  markFailed,
  SendoutQueueItem,
} from "./reducers/sendoutQueueSlice"
import { fetchArchivePage } from "./api/xeps/MAM"
import { isAfter, addSeconds } from "date-fns"

type SendoutQueueWorker = {
  send: (messageId: string) => void
}

export const getAckGroupchatId = (stanza: Element): string | undefined => {
  if (!stanza.is("message")) return
  if (stanza.attrs.type === "groupchat") {
    return stanza.attrs.id
  }

  // MAM response
  const mamMessage = stanza
    ?.getChild("result", "urn:xmpp:mam:2")
    ?.getChild("forwarded", "urn:xmpp:forward:0")
    ?.getChild("message")
  if (mamMessage?.attrs?.type === "groupchat") {
    return mamMessage.attrs.id
  }

  return undefined
}

const getLastMessages = async (
  client: Client,
  roomJid: string,
  n: number,
  timeout: number,
): Promise<Element[]> => {
  const result = await fetchArchivePage(
    client,
    {
      toJid: roomJid,
      before: true,
      max: n.toString(),
    },
    timeout,
  )
  return result.accumulator
}

const SendoutQueueWorker = (
  store: AppStore,
  xmpp: Client,
  timeout: number = 3000,
): SendoutQueueWorker => {
  const MAX_ATTEMPTS = 4
  const acker = BatchAcker({ debounce: 200, store })

  xmpp.on("stanza", (stanza: Element) => {
    const id = getAckGroupchatId(stanza)
    if (!id) return

    acker(id)
  })

  const waitForRoomSync = async (
    roomJid: string,
    messageId: string,
    store: AppStore,
    maxAttempts: number = 30,
    timeoutMs: number = 1000,
  ): Promise<boolean> => {
    let attempts = 0

    while (attempts < maxAttempts) {
      const syncedJids = store.getState().roomSyncStatus.syncedJids

      if (syncedJids.includes(roomJid)) {
        return true
      }

      console.log(
        `[SendoutQueueWorker] Delaying send attempt for ${messageId} due to room not yet synced ${roomJid}. Attempt ${attempts + 1}/${maxAttempts}`,
      )

      window.analytics.track("Sendout.WaitForSync", {
        messageId,
        roomJid,
        attempt: attempts + 1,
        maxAttempts,
      })

      await new Promise((resolve) => setTimeout(resolve, timeoutMs))
      attempts++
    }

    window.analytics.track("Sendout.WaitForSyncTimeout", {
      messageId,
      attempts,
    })

    return false
  }

  const MAX_SEND_DELAY = 60 * 10

  const tooOldItem = (item: SendoutQueueItem): boolean => {
    if (!item.addedAt) {
      return false // default case for old sendout queue without this set
    }

    return isAfter(
      new Date(),
      addSeconds(new Date(item.addedAt), MAX_SEND_DELAY),
    )
  }

  const DELAYED_DELIVERY_THRESHOLD = 30

  const attachDelay = (stanza: Element, item: SendoutQueueItem) => {
    if (!item.addedAt) return
    if (
      !isAfter(
        new Date(),
        addSeconds(new Date(item.addedAt), DELAYED_DELIVERY_THRESHOLD),
      )
    )
      return

    stanza.append(
      xml("delay", { xmlns: "urn:xmpp:delay", stamp: item.addedAt }),
    )
  }

  const trySendout = async (messageId: string) => {
    const item = getItem(messageId)(store.getState().sendoutQueue)

    if (!item) return
    if (item.messageType !== "groupchat") return

    if (tooOldItem(item)) {
      window.analytics.track("Sendout.TooOld", { messageId })
      store.dispatch(markFailed({ messageId }))
      return
    }

    const roomJid = item.targetJid
    const syncSuccess = await waitForRoomSync(roomJid, messageId, store)
    if (!syncSuccess) {
      console.warn(
        "[SendoutQueueWorker] Timed out waiting for room synchronization. Aborting send.",
        {
          messageId,
          roomJid,
          syncedJids: store.getState().roomSyncStatus.syncedJids,
        },
      )
      store.dispatch(markFailed({ messageId }))
      return
    }

    const xml = parse(item.stanza)
    attachDelay(xml, item)
    delete xml.attrs.from

    for (let attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      console.log(
        `[SendoutQueueWorker] Sending ${messageId}, attempt ${attempt}`,
      )
      window.analytics.track("Sendout.Attempt", { messageId, attempt })
      if (attempt === 1) {
        xmpp.send(xml)
        await new Promise((r) => setTimeout(r, timeout))
        const isFound = getItem(messageId)(store.getState().sendoutQueue)
        if (!isFound) {
          console.log(
            `[SendoutQueueWorker] Successfully sent on first try ${messageId}`,
          )
          window.analytics.track("Sendout.SuccessFirstTry", { messageId })
          return
        }
      } else {
        try {
          const messages = await getLastMessages(
            xmpp,
            item.targetJid,
            30,
            timeout,
          )
          ackFetchedStanzas(messages)
          const isFound = getItem(messageId)(store.getState().sendoutQueue)
          if (isFound) {
            console.log(
              `[SendoutQueueWorker] Did not ack ${messageId}, retrying`,
            )
            window.analytics.track("Sendout.NoAck", { messageId, attempt })
            xmpp.send(xml)
          } else {
            console.log(
              `[SendoutQueueWorker] Successfully sent ${messageId}, attempt=${attempt}`,
            )
            window.analytics.track("Sendout.Success", { messageId, attempt })
            return
          }
        } catch (error) {
          console.log(error)
          console.log(
            `[SendoutQueueWorker] Did not fetch archive to find ${messageId}, retrying`,
          )
          window.analytics.track("Sendout.NoFetch", { messageId, attempt })
        } finally {
          await new Promise((r) => setTimeout(r, timeout))
        }
      }
    }

    store.dispatch(markFailed({ messageId: messageId }))
  }

  const ackFetchedStanzas = (stanzas: Element[]) => {
    for (const stanza of stanzas) {
      const id = getAckGroupchatId(stanza)
      if (!id) return

      acker(id, { noDebounce: true })
    }
  }

  const send = (messageId: string) => {
    trySendout(messageId)
  }

  return { send }
}

export default SendoutQueueWorker
