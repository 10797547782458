import React from "react"
import { JID } from "@xmpp/jid"
import { Client, client } from "@xmpp/client"
import debug from "@xmpp/debug"
import XmppEventHandlers from "./xmppEventHandlers"
import type { AppStore } from "../reducers/store"
import XmppAnalytics from "./xmppAnalytics"
import * as Sentry from "@sentry/react"
import { captureError } from "../ErrorHandlers"
import SendoutQueueWorker from "../SendoutQueueWorker"
import MUC from "../xmpp_plugins/MUC"
import { markMetricDuration } from "../loadingMetrics"
import { keepConnected } from "./keepConnected"

let initializedXmppClientJid: JID | null = null

export const setupXmppListeners = (XMPP: Client, store: AppStore) => {
  XMPP.on("error", XmppEventHandlers.handleOnError)
  XMPP.on("disconnect", XmppEventHandlers.handleOnDisconnect(store.dispatch))
  XMPP.on("offline", XmppEventHandlers.handleOnOffline(store.dispatch))
  XMPP.on("stanza", XmppEventHandlers.handleOnStanza(store, XMPP))
  XMPP.on("online", (jid) => XmppEventHandlers.handleOnOnline(XMPP, store, jid))
  XMPP.on("status", XmppEventHandlers.handleConnectionStatusChange(XMPP))
  XMPP.reconnect.on("reconnecting", () =>
    XmppEventHandlers.handleReconnectStatusChange("reconnecting"),
  )
  XMPP.reconnect.on("reconnected", () =>
    XmppEventHandlers.handleReconnectStatusChange("reconnected"),
  )
  window.sendoutQueueWorker = SendoutQueueWorker(store, XMPP)
  window.mucPlugin = MUC(XMPP, (roomJid: string) =>
    XmppEventHandlers.handleRoomConfigChange(XMPP, roomJid, store.dispatch),
  )

  const { iqCallee } = XMPP

  iqCallee.set(
    "http://jabber.org/protocol/commands",
    "command",
    XmppEventHandlers.handleNewClientVersionCommand(store.dispatch),
  )

  if (process.env.NODE_ENV !== "test") {
    XMPP.on("send", XmppAnalytics.trackOnSend(XMPP))
    XMPP.on(
      "status",
      XmppAnalytics.trackOnConnectionStatus.bind(undefined, XMPP),
    )
    XMPP.on("error", XmppAnalytics.trackOnError.bind(undefined, XMPP))
    XMPP.reconnect.on(
      "reconnecting",
      XmppAnalytics.trackOnReconnecting.bind(undefined, XMPP),
    )
    XMPP.reconnect.on(
      "reconnected",
      XmppAnalytics.trackOnReconnected.bind(undefined, XMPP),
    )
  }
}

export const setXmppOnWindow = (userAuth: Types.Api.UserAuth) => {
  const [userName, domain] = userAuth.email.split("@")

  if (!userName || !domain) {
    return false
  }

  const xmpp = client({
    service: process.env.REACT_APP_XMPP_SERVICE,
    domain: domain,
    username: userName,
    password: userAuth.password,
  })
  window.XMPP = xmpp
  window.providedEmail = userAuth.email

  return true
}

export type ConnectionAttemptResult =
  | {
      connected: true
      jid: JID
    }
  | {
      connected: false
      error: "not-authorized" | "other" | null
    }

export const connect = async (
  store: AppStore,
): Promise<ConnectionAttemptResult> => {
  return Sentry.startSpanManual(
    { name: "connect", op: "xmpp" },
    async (span) => {
      const start = window.performance.now()
      const xmpp = window.XMPP

      if (!xmpp) {
        return { connected: false, error: "other" }
      }

      if (initializedXmppClientJid) {
        window.analytics.track("MultipleConnectAttempt")
        return { connected: true, jid: initializedXmppClientJid }
      }

      setupXmppListeners(xmpp, store)

      debug(xmpp, process.env.REACT_APP_XMPP_DEBUG === "1")

      try {
        const jid = await xmpp.start()
        keepConnected(xmpp)
        initializedXmppClientJid = jid
        return { connected: true, jid }
      } catch (error: any) {
        if (
          error.message === "host-unknown" ||
          error.message.includes("not-authorized")
        ) {
          return { connected: false, error: "not-authorized" }
        }
        captureError(error, { origin: "InitialLoad" })
        await xmpp.stop()
        return { connected: false, error: "other" }
      } finally {
        if (span) span.end()
        markMetricDuration("xmppConnect", window.performance.now() - start)
      }
    },
  )
}

export const resetCredentialsAndConnect = async (
  userAuth: Types.Api.UserAuth,
  store: AppStore,
): Promise<ConnectionAttemptResult> => {
  setXmppOnWindow(userAuth)
  return await connect(store)
}

export type XmppContextType = {
  client: Client
  myJid: JID
}

export const xmppServiceAddress = (
  serviceName: string,
  client: Client,
): string => {
  if (!client.jid)
    throw `Can not build service address for ${serviceName}, jid on xmpp client is missing`

  return `${serviceName}.${client.jid.domain}`
}

export const XmppContext = React.createContext({} as XmppContextType)
