import { useContext } from "react"
import { useAppSelector } from "../reducers/hooks"
import { XmppContext } from "../stream/xmppClient"
import {
  hasMessageFileBeenShared,
  selectActiveChat,
  selectMessageChat,
} from "../reducers/chatsSliceSelectors"
import { Message } from "../reducers/chatsSlice"

export default function useHasFileBeenShared(message: Message) {
  const activeChat = useAppSelector(selectActiveChat)
  const { myJid } = useContext(XmppContext)
  const originalMessageChat = useAppSelector(
    selectMessageChat(message, myJid.toString()),
  )
  return Boolean(
    hasMessageFileBeenShared(activeChat, message.id) ||
      (originalMessageChat &&
        hasMessageFileBeenShared(originalMessageChat, message.id)),
  )
}
