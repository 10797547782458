import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"

export type Location = {
  lat?: string
  lon?: string
  bearing?: string
  country?: string
  countryCode?: string
  locality?: string
  street?: string
  postalCode?: string
  formattedAddress?: string
}

type DriverLocation = {
  jid: string
  itemId: string
  value: Location | undefined
}

export type Locations = {
  items: DriverLocation[]
}

const initialState: Locations = {
  items: [],
}

type UpsertLocationAction = {
  jid: string
  itemId: string
  value: Location | undefined
}

const locations = createSlice({
  name: "locations",
  initialState,
  reducers: {
    upsertLocation: (
      state: Locations,
      action: PayloadAction<UpsertLocationAction>,
    ) => {
      const index = state.items.findIndex(
        (i) =>
          i.jid === action.payload.jid && i.itemId === action.payload.itemId,
      )
      if (index < 0) {
        state.items.push({
          jid: action.payload.jid,
          itemId: action.payload.itemId,
          value: action.payload.value,
        })
      } else {
        state.items[index] = {
          jid: action.payload.jid,
          itemId: action.payload.itemId,
          value: action.payload.value,
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState)
  },
})

export const { upsertLocation } = locations.actions

export default locations.reducer
