import { PayloadAction, createSlice, createSelector } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"
import { Location } from "./locationsSlice"
import { RootState } from "./store"
import {
  BookmarkCheck,
  Fuel,
  LucideIcon,
  MapPin,
  MapPinPlusInside,
  PackageCheck,
  Ship,
  ShoppingCart,
  SquareParking,
} from "lucide-react"

export type RouteStop = {
  id?: string
  by?: string
  location: Location
  msgId?: string
  addressUpdated?: boolean
  action?: string
  ref?: string
  remaining_distance?: number
  remaining_time?: number
  scheduled_arrival_time?: string
  estimated_arrival_time?: string
  proximity_status?: string
  timeliness_status?: string
  is_active?: boolean
  is_current_target?: boolean
  is_late?: boolean
}

export type Route2g = {
  jid: string
  stops: RouteStop[]
}

export type Action = {
  label: string
  value: string
  icon: LucideIcon
}

export const ACTIONS: Action[] = [
  { label: "Pickup", value: "pickup", icon: ShoppingCart },
  { label: "Delivery", value: "delivery", icon: PackageCheck },
  { label: "Refuel", value: "refuel", icon: Fuel },
  { label: "Parking", value: "parking", icon: SquareParking },
  { label: "Ferry", value: "ferry", icon: Ship },
  { label: "Customs", value: "customs", icon: BookmarkCheck },
  { label: "Waypoint", value: "waypoint", icon: MapPinPlusInside },
  { label: "Other", value: "other", icon: MapPin },
]

export const isStopActive = (stop: RouteStop): boolean => {
  /*
    NOTE this is slightly different definition than in back-end,
    where we don't consider arrived as active and don't include
    this point in route calculation any more
  */
  return (
    stop.proximity_status !== undefined &&
    ["arrived", "arriving", "far"].includes(stop.proximity_status)
  )
}

export const isStopPending = (stop: RouteStop): boolean => {
  return (
    stop.proximity_status !== undefined &&
    ["arriving", "far"].includes(stop.proximity_status)
  )
}

export const isStopLate = (stop: RouteStop): boolean => {
  return stop.timeliness_status === "late"
}

export const findCurrentTarget = (
  stops: RouteStop[] | undefined,
): RouteStop | undefined => {
  return stops?.find(
    (stop) =>
      stop.proximity_status === "arrived" ||
      stop.proximity_status === "arriving" ||
      stop.proximity_status === "far",
  )
}

type Route2gState = Route2g[]

const initialState: Route2gState = []

type UpsertRouteAction = {
  route: Route2g
}

const routes = createSlice({
  name: "routes2g",
  initialState,
  reducers: {
    upsertRoute2g: (state, action: PayloadAction<UpsertRouteAction>) => {
      const routeIndex = state.findIndex(
        (p) => p.jid === action.payload.route.jid,
      )

      if (routeIndex === -1) {
        state.push(action.payload.route)
      } else {
        state[routeIndex] = action.payload.route
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState)
  },
})

export const findRoute2g = (jid: string | undefined) =>
  createSelector(
    (state: RootState) => state.routes2g,
    (routes2g) => routes2g.find((route) => route.jid === jid),
  )

export const { upsertRoute2g } = routes.actions

export default routes.reducer
