import * as Sentry from "@sentry/react"
import afterFrame from "afterframe"
import { isMobile } from "../utils/detectDevice"

window.APP_VERSION = process.env.REACT_APP_VERSION
  ? process.env.REACT_APP_VERSION
  : "undefined"

window.APP_ENVIRONMENT = process.env.REACT_APP_ENV_NAME || "undefined"

Sentry.init({
  enabled: window.APP_ENVIRONMENT !== "undefined",
  dsn: isMobile
    ? "https://670762dab96ddb311f62f8821c051fcd@o4506671058976768.ingest.us.sentry.io/4507057244012544"
    : "https://380368574f7f7bf3399fee4dedbbe4da@o4506671058976768.ingest.sentry.io/4506671061467136",
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  release: window.APP_VERSION,
  environment: window.APP_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration({
      instrumentNavigation: false,
    }),
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: process.env.NODE_ENV === "production" ? 1.0 : 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

window.pageLoadSpan = Sentry.startBrowserTracingPageLoadSpan(
  Sentry.getClient()!,
  {
    name: window.location.pathname,
    op: "pageload",
    attributes: { start: performance.now() },
  },
)

export const measurePerformance = (
  name: string,
  attributes: { [key: string]: string | number } = {},
) => {
  const perfSpan = Sentry.startBrowserTracingPageLoadSpan(Sentry.getClient()!, {
    name: name,
    op: "ux.interaction",
    attributes: attributes,
  })
  const startMark = performance.mark(`${name}.start`, { detail: attributes })

  return {
    end: () => {
      afterFrame(() => {
        const endMark = performance.mark(`${name}.finish`)
        performance.measure(`${name}.duration`, {
          start: startMark.name,
          end: endMark.name,
          detail: attributes,
        })

        if (perfSpan) perfSpan.end()
      })
    },
  }
}
