import React from "react"
import { Tag } from "@blueprintjs/core"

const SignedCMRTag = ({ inGroup = false }: { inGroup?: boolean }) => {
  return (
    <Tag
      round
      intent="primary"
      large={!inGroup}
      className="absolute bottom-2 left-2 !bg-online"
    >
      <div className="flex flex-row gap-x-1">
        <img
          className={inGroup ? "h-3 mt-0.5" : "h-4 mt-0.5"}
          src="/signed.svg"
        />
        {!inGroup && <span className="text-black">Signed</span>}
      </div>
    </Tag>
  )
}

export default SignedCMRTag
