import React, {
  Ref,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { captureError } from "../ErrorHandlers"
import css from "./ChatHistory.module.css"
import cssUtils from "./utilsCss.module.css"
import { XmppContext } from "../stream/xmppClient"
import {
  Chat,
  collapseImageGroups,
  hasTheId,
  Message,
  MessageDisplayType,
  MessageGroup,
  Participant,
} from "../reducers/chatsSlice"
import ChatHelpers from "../lib/chatHelpers"
import ScrollBottomButton from "./ScrollBottomButton"
import { useAppDispatch, useAppSelector } from "../reducers/hooks"
import { messageStatuses } from "../reducers/selectors"
import {
  selectChatMessagesWithAppliedRetractionsMemo,
  selectDriverParticipant,
} from "../reducers/chatsSliceSelectors"
import { selectLocation } from "../reducers/locationsSelectors"
import MessageHelpers, { MessageStatus } from "../lib/messageHelpers"
import RouteView from "./RouteView"
import Loader from "./Loader"
import useInfiniteScroll from "../hooks/useInfiniteScroll"
import { DriverContext } from "../contexts/DriverContext"
import AdHocCommands from "../api/adHocCommands"
import { isMobile } from "../utils/detectDevice"
import { useDrop, DropTargetMonitor } from "react-dnd"
import { NativeTypes } from "react-dnd-html5-backend"
import { VirtualItem, useVirtualizer } from "@tanstack/react-virtual"
import { ChatInputTextAreaRef } from "./ChatInput"
import { ChatHistoryContext } from "../contexts/ChatHistoryContext"
import MessageRowOrImageGroupRow from "./message/MessageRowOrImageGroupRow"
import { shallowEqual } from "react-redux"
import {
  selectHasRoomSyncFailed,
  selectIsRoomSyncFinished,
} from "../reducers/roomSyncStatusSelectors"
import * as Sentry from "@sentry/react"
import ErrorWithRetry from "./ErrorWithRetry"
import { retrySyncSingleRoom } from "../reducers/roomSyncStatusSliceThunks"
import { driverJid } from "../jidUtils"
import ImageGallery from "./photoView/ImageGallery"
import { findRoute2g, isStopPending, Route2g } from "../reducers/routes2gSlice"
import PDFViewer from "./pdfView/PDFViewer"
import DayMarker from "./DayMarker"
import { getCurrentMessageDate } from "./message/utils/messageDate"
import {
  isImageGalleryVisible,
  isPDFViewerVisible,
} from "../reducers/overlaysSlice"

export type ChatHistoryHandle = {
  scrollToBottom: () => void
}

type ChatProps = {
  chat: Chat
  chatHistoryRef: Ref<ChatHistoryHandle | null>
  chatInputRef: RefObject<ChatInputTextAreaRef>
  markMessageDisplayed: (message: Message) => void
  onFileDropped: (files: FileList) => void
}

const LOADING_PLACEHOLDER = Symbol("loading-placeholder")
const ERROR_PLACEHOLDER = Symbol("error-placeholder")
type PlaceholderType = typeof LOADING_PLACEHOLDER | typeof ERROR_PLACEHOLDER
const isPlaceholder = (row: any): row is PlaceholderType =>
  typeof row === typeof LOADING_PLACEHOLDER ||
  typeof row === typeof ERROR_PLACEHOLDER
export const MESSAGE_HIGHLIGHT_DURATION_MS = 1250

const ChatHistory = ({
  chat,
  chatHistoryRef,
  chatInputRef,
  markMessageDisplayed,
  onFileDropped,
}: ChatProps) => {
  const [hasMoreMessages, setHasMoreMessages] = useState(false)
  const [loadingMessages, setLoadingMessages] = useState(false)
  const { myJid, client } = useContext(XmppContext)
  const dispatch = useAppDispatch()
  const driverParticipant = useAppSelector(selectDriverParticipant(chat.jid))
  const currentLocation = useAppSelector(
    selectLocation(driverJid(chat.jid), "current"),
  )
  const route2g = useAppSelector(findRoute2g(driverJid(chat.jid)))
  const isRoomSyncFinished = useAppSelector(selectIsRoomSyncFinished(chat.jid))

  const hasRoomSyncFailed = useAppSelector(selectHasRoomSyncFailed(chat.jid))
  const [, fetchMoreMessages] = useInfiniteScroll()

  const chatMessages = useAppSelector((state) =>
    selectChatMessagesWithAppliedRetractionsMemo(state, chat),
  )

  const messageHighlightTimeoutRef = useRef<NodeJS.Timeout>()
  const [messageHighlight, setMessageHighlight] = useState<
    { messageId: string; animationKey: string } | undefined
  >()

  const containerRef = useRef<HTMLDivElement>(null)

  const unreadCount = useMemo(() => {
    return ChatHelpers.unreadMessagesCount(chat, myJid.toString())
  }, [myJid, chat])

  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set())

  const rows = useMemo(() => {
    const rows: (Message | PlaceholderType | MessageGroup)[] =
      collapseImageGroups(chatMessages.slice(), expandedGroups)

    if (loadingMessages) rows.unshift(LOADING_PLACEHOLDER)
    if (!isRoomSyncFinished) rows.push(LOADING_PLACEHOLDER)
    if (hasRoomSyncFailed) rows.push(ERROR_PLACEHOLDER)
    return rows
  }, [
    chatMessages,
    loadingMessages,
    expandedGroups,
    isRoomSyncFinished,
    hasRoomSyncFailed,
  ])

  const correctedMessages = useMemo(() => {
    return rows.map((row) =>
      !isPlaceholder(row)
        ? MessageHelpers.applyCorrections(row, chat.messageCorrections)
        : row,
    )
  }, [rows, chat.messageCorrections])

  const { current: self } = useRef({
    prevChatJid: undefined as string | undefined,
    prevFirstMessage: undefined as Message | undefined,
    prevLastMessage: undefined as Message | undefined,
    lastMessageMarkedAsDisplayed: undefined as Message | undefined,
    scrollPinnedToBottom: false,
    scrollTimer: undefined as number | undefined,
    scrollOnDone: undefined as (() => void) | undefined,
    cancelScroll: () => {
      if (self.scrollTimer) {
        cancelAnimationFrame(self.scrollTimer)
        self.scrollTimer = undefined
      }
    },
    scroll: ({
      scrollToIndex,
      scrollToOffset,
      smoothScroll = false,
      scrollRetries = 0,
      onDone,
    }: {
      scrollToIndex?: number
      scrollToOffset?: number
      smoothScroll?: boolean
      scrollRetries?: number
      onDone?: () => void
    }) => {
      const doScroll = () => {
        self.cancelScroll()
        self.scrollOnDone = onDone
        // it may be null in unit tests
        if (!containerRef.current) return
        if (
          virtualizer.options.count > 0 &&
          (scrollToIndex !== undefined || scrollToOffset !== undefined)
        ) {
          // -1 is just end-of-list, which can be much simplified
          if (scrollToIndex === -1) {
            scrollToOffset = containerRef.current.scrollHeight
          } else if (scrollToIndex !== undefined) {
            const alignment = virtualizer.getOffsetForIndex(
              scrollToIndex >= 0
                ? scrollToIndex
                : virtualizer.options.count + scrollToIndex,
              "start",
            )
            scrollToOffset = virtualizer.getOffsetForAlignment(
              alignment[0],
              alignment[1],
            )
          } else if (scrollToOffset === undefined) {
            scrollToOffset = containerRef.current.scrollHeight
          }

          if (smoothScroll) {
            virtualizer.scrollToOffset(scrollToOffset, {
              align: "start",
              behavior: "smooth",
            })
          } else {
            containerRef.current.scrollTop = scrollToOffset
          }
        }
        // Virtualizer has to render & measure rows at position. This may require a couple frames to nail
        if (scrollRetries-- > 0) {
          self.scrollTimer = requestAnimationFrame(doScroll)
        } else {
          self.scrollOnDone?.()
          self.scrollOnDone = undefined
        }
      }
      doScroll()
    },
  })

  const virtualizer = useVirtualizer({
    count: rows.length,
    overscan: isMobile ? 50 : 100,
    scrollPaddingStart: 100,
    scrollPaddingEnd: 100,
    getScrollElement: useCallback(() => containerRef.current, []),
    estimateSize: useCallback(
      (index) => {
        const row = rows[index]
        // Simple guesstimates
        if (isPlaceholder(row)) return 40
        if (row.displayType === MessageDisplayType.MessageGroup) return 700
        if (row.url && /\.png|\.jpg|\.jpeg/i.test(row.url)) {
          return 400
        }
        return 100
      },
      [rows],
    ),
    measureElement: useCallback((element: HTMLElement) => {
      const height =
        element.clientHeight +
        (parseFloat(getComputedStyle(element).marginTop) || 0)
      return height
    }, []),
  })

  const virtualRows = virtualizer.getVirtualItems().slice().reverse()
  const firstRow = virtualRows[virtualRows.length - 1]
  const lastRow = virtualRows[0]
  const firstMessage = chatMessages[0]
  const lastMessage = chatMessages.at(-1)

  const offsetToFirstItem = firstRow ? firstRow.start : 0
  const offsetToLastItem = virtualizer.getTotalSize() - (lastRow?.end || 0)

  const loaderIsWithinView =
    hasMoreMessages && virtualizer.range && virtualizer.range.startIndex <= 1

  const lastItemIsWithinView =
    rows.length === 0 ||
    (virtualizer.range && virtualizer.range.endIndex === rows.length - 1)

  const isScrollbarVisible =
    containerRef.current &&
    containerRef.current.scrollHeight > containerRef.current.clientHeight

  const scrollToBottom = useCallback(() => {
    self.scroll({
      scrollToIndex: -1,
      smoothScroll: true,
    })
    self.scrollPinnedToBottom = true
  }, [self])

  useImperativeHandle(
    chatHistoryRef,
    () => ({
      scrollToBottom,
    }),
    [scrollToBottom],
  )

  // --- Marking as displayed ---
  useEffect(() => {
    if (
      lastItemIsWithinView &&
      lastMessage &&
      lastMessage.id !== self.lastMessageMarkedAsDisplayed?.id
    ) {
      markMessageDisplayed(lastMessage)
      self.lastMessageMarkedAsDisplayed = lastMessage
    }
  }, [lastItemIsWithinView, lastMessage, markMessageDisplayed, self])

  // --- Tracking pageload duration metric ---
  useEffect(() => {
    if (window.pageLoadSpan) {
      performance.measure(`PageLoad.duration`, {
        start: window.pageLoadSpan.attributes.start as number,
        end: performance.now(),
        detail: {
          path: window.pageLoadSpan.name,
        },
      })
      window.pageLoadSpan.end()
      window.pageLoadSpan = undefined
    }
  }, [])

  // --- Scroll pinning ---
  const scrollBottom = virtualizer.scrollOffset + virtualizer.scrollRect.height

  if (virtualizer.isScrolling && virtualizer.scrollDirection === "backward") {
    if (self.scrollPinnedToBottom) {
      self.scrollPinnedToBottom = false
    }
  }

  if (scrollBottom >= virtualizer.getTotalSize() - 100) {
    if (!self.scrollPinnedToBottom) {
      self.scrollPinnedToBottom = true
    }
  }

  const [isFileDropActive, dropRef] = useDrop<
    { files: FileList },
    unknown,
    boolean
  >(() => ({
    accept: [NativeTypes.FILE],
    drop({ files }: { files: FileList }) {
      onFileDropped(files)
    },
    collect(monitor: DropTargetMonitor) {
      return monitor.canDrop() && monitor.isOver()
    },
  }))

  const [topVisibleMessage, setTopVisibleMessage] = useState<Message | null>(
    null,
  )

  const updateTopVisibleMessage = useCallback(() => {
    if (!containerRef.current) return

    const containerTop = containerRef.current.scrollTop
    const containerBottom = containerTop + containerRef.current.clientHeight

    // Iterate from the end of virtualRows (which is actually the top of the chat)
    for (let i = virtualRows.length - 1; i >= 0; i--) {
      const virtualRow = virtualRows[i]
      const rowTop = virtualRow.start
      const rowBottom = virtualRow.end

      // Check if the row is visible (even partially)
      if (rowBottom > containerTop && rowTop < containerBottom) {
        const row = rows[virtualRow.index]
        if (!isPlaceholder(row)) {
          setTopVisibleMessage(row as Message)
          break
        }
      }
    }
  }, [virtualRows, rows])

  useEffect(() => {
    updateTopVisibleMessage()
  }, [virtualizer.scrollOffset, updateTopVisibleMessage])

  // --- Updating scroll position whenever new chat is opened or new message arrives/is loaded ---
  useLayoutEffect(
    function onNewChatOrMessage() {
      const isNewChat = self.prevChatJid !== chat.jid
      const isNewFirstMessage = self.prevFirstMessage?.id !== firstMessage?.id
      const isNewLastMessage = self.prevLastMessage?.id !== lastMessage?.id

      if (!isNewChat && !isNewFirstMessage && !isNewLastMessage) {
        // react strict is re-running this effect
        return
      }

      let scrollToIndex: number | undefined
      let scrollToOffset: number | undefined
      let scrollRetries = 0
      let smoothScroll = false

      if (isNewChat) {
        // new chats are not smooth and need to be retried a couple of times
        // the built-in scrollToOffset is not reliable and can lock up the scroll!
        scrollRetries = 2
        scrollToIndex = -1 // for now force to scroll to last message
      } else if (isNewFirstMessage) {
        if (self.scrollPinnedToBottom) {
          scrollToIndex = -1
          // we don't know the exact size of items at bottom, so we might need to retry
          scrollRetries = 1
        } else {
          // we shift the current scroll position by the height of all new messages
          const prevFirstMessageIndex = self.prevFirstMessage
            ? rows.findIndex(
                (r) =>
                  typeof r === "object" &&
                  hasTheId(r, self.prevFirstMessage!.id),
              )
            : -1
          if (prevFirstMessageIndex > 0) {
            const [newMessagesHeight] = virtualizer.getOffsetForIndex(
              prevFirstMessageIndex,
              "start",
            )
            scrollToOffset = containerRef.current!.scrollTop + newMessagesHeight
          }
        }
      } else if (isNewLastMessage) {
        smoothScroll = true
        if (self.scrollPinnedToBottom) {
          scrollToIndex = -1
        }
      }

      self.prevChatJid = chat.jid
      self.prevFirstMessage = firstMessage
      self.prevLastMessage = lastMessage
      self.lastMessageMarkedAsDisplayed = undefined
      self.scroll({
        scrollToIndex,
        scrollToOffset,
        smoothScroll,
        scrollRetries,
        onDone: () => {
          // The condition here is to setHasMoreMessages to true
          // in case if this is chat that was swtched to
          // or both the first and last messages changed (as this means the initial sync loaded some messages)
          if (isNewChat || (isNewLastMessage && isNewFirstMessage)) {
            setHasMoreMessages(true)
          }
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chat.jid, firstMessage, lastMessage],
  )

  // --- Fetching more messages ---
  const onFetchMoreMessages = useCallback(async () => {
    setHasMoreMessages(false)
    setLoadingMessages(true)
    const hasMore = await fetchMoreMessages(
      chat,
      client,
      myJid.toString(),
      dispatch,
    )
    setLoadingMessages(false)
    if (hasMore) {
      setHasMoreMessages(hasMore)
    }
  }, [chat, client, dispatch, fetchMoreMessages, myJid])

  useEffect(() => {
    if (loaderIsWithinView) {
      onFetchMoreMessages()
    }
  }, [loaderIsWithinView, onFetchMoreMessages])

  const expandImageGroup = useCallback(
    (messageOrMessageGroup: Message | MessageGroup) => {
      messageOrMessageGroup.displayType == MessageDisplayType.MessageGroup &&
        setExpandedGroups(
          (prev) =>
            new Set(prev.add((messageOrMessageGroup as MessageGroup).groupId)),
        )
    },
    [],
  )

  const renderMessageRow = (virtualRow: VirtualItem) => {
    const messageOrMessageGroup = correctedMessages[virtualRow.index]

    if (isPlaceholder(messageOrMessageGroup)) {
      // Handle placeholder rendering
      return null
    }

    const prevRow = rows[virtualRow.index - 1]
    const prevMessageOrMessageGroup = !isPlaceholder(prevRow)
      ? prevRow
      : undefined

    const isGrouped = MessageHelpers.shouldGroup(
      messageOrMessageGroup,
      prevMessageOrMessageGroup,
    )

    const getStatus = (
      statuses: Record<string, MessageStatus | undefined>,
      messageOrGroup: Message | MessageGroup,
    ): MessageStatus | undefined => {
      if ("id" in messageOrGroup) {
        return statuses[messageOrGroup.id]
      } else {
        return "sent"
      }
    }

    const status = getStatus(statuses, messageOrMessageGroup)

    return (
      <MessageRowOrImageGroupRow
        chatJid={chat.jid}
        data-testid="message-row"
        data-index={virtualRow.index}
        key={virtualRow.key}
        ref={virtualizer.measureElement}
        className={css.chatMessage}
        messageOrMessageGroup={messageOrMessageGroup}
        previousMessageOrMessageGroup={prevMessageOrMessageGroup}
        status={status}
        highlight={MessageHelpers.hasComeFromJid(
          messageOrMessageGroup,
          driverParticipant?.jid,
        )}
        grouped={isGrouped}
        withDriver={!!driverParticipant}
        chatInputRef={chatInputRef}
        expand={expandImageGroup}
      />
    )
  }

  const statuses = useAppSelector(messageStatuses(chat, myJid), shallowEqual)

  // --- Rendering ---
  const renderVirtualRow = (virtualRow: VirtualItem) => {
    const row = rows[virtualRow.index]
    if (row === LOADING_PLACEHOLDER) {
      return (
        <div
          data-index={virtualRow.index}
          key={virtualRow.key}
          ref={virtualizer.measureElement}
          className={css.chatLoader}
        >
          <Loader className={cssUtils.marginAuto} small />
        </div>
      )
    }

    if (row === ERROR_PLACEHOLDER) {
      return (
        <div
          data-index={virtualRow.index}
          key={virtualRow.key}
          ref={virtualizer.measureElement}
          className={css.chatLoader}
        >
          <ErrorWithRetry
            onRetry={() => dispatch(retrySyncSingleRoom(chat.jid))}
            errorMessage="Error loading messages"
          />
        </div>
      )
    }
    return renderMessageRow(virtualRow)
  }

  const setDriverNextDestination = useCallback(
    (address: string, driver: Participant) => {
      AdHocCommands.setDriverDestination(
        client,
        `${driver.jid}/driverbot`,
        address,
      )
        .then((result) => {
          const status = result.getChild("command")?.attrs.status
          if (status !== "completed") {
            captureError("Error", {
              origin: "UserAction",
              extra: {
                message: "setDriverNextDestination",
                jid: driver.jid,
                status,
              },
            })
            window.analytics.track("FailedDestinationSet")
          }
        })
        .catch((error) => {
          captureError(error, {
            origin: "UserAction",
            extra: { message: "setDriverNextDestination", jid: driver.jid },
          })
        })
    },
    [client],
  )

  const cancelDestination = useCallback(
    (driver: Participant) => {
      window.analytics.track("RemoveDriverDestination")
      AdHocCommands.removeDriverDestination(client, `${driver.jid}/driverbot`)
        .then((result) => {
          const status = result.getChild("command")?.attrs.status
          if (status !== "completed") {
            captureError("Error", {
              origin: "UserAction",
              extra: { message: "cancelDestination", jid: driver.jid, status },
            })
            window.analytics.track("FailedDestinationRemove")
          }
        })
        .catch((error) => {
          captureError(error, {
            origin: "UserAction",
            extra: { message: "cancelDestination", jid: driver.jid },
          })
        })
    },
    [client],
  )

  const upsertRoute2g = useCallback(
    (route: Route2g, driver: Participant) => {
      window.analytics.track("UpdateRoute2g")
      AdHocCommands.upsertRoute2g(client, `${driver.jid}/driverbot`, route)
        .then((result) => {
          if (result.getChild("command")?.attrs.status !== "completed") {
            Sentry.captureMessage(
              `Error while setting driver destination: ${result.toString()}`,
            )
            window.analytics.track("FailedUpsertRouteStops")
          }
        })
        .catch((error) => {
          captureError(error, {
            origin: "UserAction",
            extra: { message: "upsertRouteStops", jid: driver.jid },
          })
        })
    },
    [client],
  )

  const addRouteStop = useCallback(
    (address: string, driver: Participant, msgId: string) => {
      const stop = { location: { formattedAddress: address }, msgId: msgId }
      const currentStops = route2g?.stops || []
      const stops = [...currentStops, stop]
      const index = currentStops.filter((stop) => isStopPending(stop)).length
      window.analytics.track("AddRouteStop", {
        source: "address_link",
        index: index,
        multi: index > 0,
        msgId: msgId,
      })
      AdHocCommands.upsertRoute2g(client, `${driver.jid}/driverbot`, {
        jid: driver.jid,
        stops: stops,
      })
        .then((result) => {
          if (result.getChild("command")?.attrs.status !== "completed") {
            Sentry.captureMessage(
              `Error while setting driver destination: ${result.toString()}`,
            )
            window.analytics.track("FailedAddRouteStop")
          }
        })
        .catch((error) => {
          captureError(error, {
            origin: "UserAction",
            extra: { message: "addRouteStop", jid: driver.jid },
          })
        })
    },
    [client, route2g],
  )

  const messageIdRowIndex = useCallback(
    (messageId: string) => {
      return rows.findIndex((r) => {
        if (isPlaceholder(r)) {
          return false
        }

        return hasTheId(r, messageId)
      })
    },
    [rows],
  )

  const scrollChatToMessage = useCallback(
    (id: string) => {
      const chatIndex = messageIdRowIndex(id)
      if (chatIndex >= 0) {
        self.scroll({
          scrollToIndex: chatIndex,
        })
      }
    },
    [self, messageIdRowIndex],
  )

  const setMessageAsHighlighted = useCallback(
    (messageId: string) => {
      setMessageHighlight({
        messageId,
        animationKey: Math.random().toString(),
      })
      if (messageHighlightTimeoutRef.current) {
        clearTimeout(messageHighlightTimeoutRef.current)
      }
      messageHighlightTimeoutRef.current = setTimeout(() => {
        setMessageHighlight(undefined)
      }, MESSAGE_HIGHLIGHT_DURATION_MS)
    },
    [messageHighlightTimeoutRef, setMessageHighlight],
  )

  // Context has to be memoized - otherwise whole tree is re-rendered on every frame!
  const driverContext: DriverContext = useMemo(
    () => ({
      setDriverNextDestination,
      cancelDestination,
      upsertRoute2g,
      addRouteStop,
      driver: driverParticipant,
    }),
    [
      setDriverNextDestination,
      cancelDestination,
      upsertRoute2g,
      addRouteStop,
      driverParticipant,
    ],
  )

  const jumpAndHighlightMessage = useCallback(
    (messageId: string) => {
      scrollChatToMessage(messageId)
      setMessageAsHighlighted(messageId)
    },
    [scrollChatToMessage, setMessageAsHighlighted],
  )

  const chatHistoryContext = useMemo(() => {
    return {
      messageHighlight,
      jumpAndHighlightMessage,
    }
  }, [messageHighlight, jumpAndHighlightMessage])

  const imageGalleryVisible = useAppSelector(isImageGalleryVisible)
  const PDFViewerVisible = useAppSelector(isPDFViewerVisible)

  return (
    <ChatHistoryContext.Provider value={chatHistoryContext}>
      {imageGalleryVisible && <ImageGallery messages={chatMessages} />}
      {PDFViewerVisible && <PDFViewer />}
      <div className={css.chatHistoryWrapper} ref={dropRef}>
        {isFileDropActive && (
          <div className={css.chatFileDropOverlay}>
            <h2>Drop file to upload</h2>
          </div>
        )}
        <DriverContext.Provider value={driverContext}>
          {currentLocation && !isMobile && (
            <RouteView location={currentLocation} route={route2g} />
          )}
          <div
            id="scrollableDiv"
            className={css.chatHistory}
            ref={containerRef}
          >
            <div
              className={css.historyContainer}
              style={{
                paddingTop: offsetToFirstItem,
                paddingBottom: offsetToLastItem,
              }}
            >
              {virtualRows.map(renderVirtualRow)}
            </div>

            {!lastItemIsWithinView &&
              !imageGalleryVisible &&
              !PDFViewerVisible && (
                <ScrollBottomButton
                  onClick={() => scrollToBottom()}
                  unreadCount={unreadCount}
                />
              )}
            {topVisibleMessage && isScrollbarVisible && (
              <div
                style={{
                  position: "sticky",
                  opacity: virtualizer.isScrolling ? 1 : 0,
                  transition: "opacity 0.5s ease",
                  bottom: "calc(100% - 60px)",
                  marginTop: "-60px",
                  zIndex: 15,
                }}
              >
                <DayMarker date={getCurrentMessageDate(topVisibleMessage)} />
              </div>
            )}
          </div>
        </DriverContext.Provider>
      </div>
    </ChatHistoryContext.Provider>
  )
}

ChatHistory.displayName = "ChatHistory"

export default Sentry.withProfiler(ChatHistory)
