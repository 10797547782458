import { Element } from "@xmpp/xml"
import { MessageDisplayType, Reaction } from "../reducers/chatsSlice"
import MessageParser from "./messageParser"

class ReactionsParser {
  static parse(stanza: Element, timestamp?: string): Reaction | undefined {
    const reactionsNode = stanza.getChild("reactions")

    if (!reactionsNode) return

    return {
      id: MessageParser.id(stanza, true),
      to: stanza.attrs.to,
      from: stanza.attrs.from,
      messageId: reactionsNode.attrs.id,
      emojis: reactionsNode
        .getChildren("reaction")
        .map((reaction) => reaction.text()),
      type: stanza.attrs.type || "chat",
      timestamp: timestamp || new Date().toISOString(),
      displayType: MessageDisplayType.Reaction,
    }
  }
}

export default ReactionsParser
