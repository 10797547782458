import { PayloadAction, createSlice, createSelector } from "@reduxjs/toolkit"
import { RootState } from "./store"
import { PURGE } from "redux-persist"

export type AppVersionState = {
  updateAvailable: boolean
  notificationSubscriptionRequestedAt: string | null
}

const initialState = {
  updateAvailable: false,
  notificationSubscriptionRequestedAt: null,
} as AppVersionState

type SetUpdateAvailableAction = {
  updateAvailable: boolean
}

export const appVersionSlice = createSlice({
  name: "appVersion",
  initialState,
  reducers: {
    setUpdateAvailable: (
      state,
      action: PayloadAction<SetUpdateAvailableAction>,
    ) => {
      state.updateAvailable = action.payload.updateAvailable
    },

    markNotificationSubscriptionRequest: (state) => {
      state.notificationSubscriptionRequestedAt = new Date().toISOString()
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState)
  },
})

export const { setUpdateAvailable, markNotificationSubscriptionRequest } =
  appVersionSlice.actions
export default appVersionSlice.reducer

export const selectUpdateAvailable = (state: RootState) => {
  return state.appVersion.updateAvailable
}

export const selectNotificationSubscriptionRequestedAt = createSelector(
  (state: RootState) => state.appVersion,
  (appVersion) => {
    return appVersion.notificationSubscriptionRequestedAt
  },
)
